import { Box } from '@mui/material';
import { BoxFC, BoxFR } from 'components/BoxCustom';
import DataGridCellExpand from 'components/DataGridCellExpand/DataGridCellExpand';
import React, { useCallback, useState, useContext, useEffect, useMemo } from 'react';
import { columns } from './columns';
import { GlobalStateContext } from 'contexts/GlobalStateContext';
import DateTimeSelect from 'components/DateTimeSelect';
import SearchButton from 'components/buttons/SearchButton';
import ClearButton from 'components/buttons/ClearButton';
import { initFilterData, clearFilterData } from './initData';
import { addIdForDataGrid } from 'utils';
import PCPeriodDriverDialog from './PCPeriodDriverDialog';
import AddButton from 'components/buttons/AddButton';
import ComboBox from 'components/ComboBox';
import {  shorePeriodColumns } from 'pages/Menu1/PettyCash/columns';
import ShorePeriodDialog from 'pages/Menu1/ShoreManagementV2/ShorePeriodDialog';
import { initPCPeriodFilterData } from 'pages/Menu1/PettyCash/initData';
import { alertError } from 'components/Alert';
import { shoreApiNm } from 'constants/shoreConstant';

let selectedId = null;
// let lastFilter = null

const PCPeriodDriver = () => {
  const { ax, msData } = useContext(GlobalStateContext)
  const [dataTable, setDataTable] = useState([])
  const [dialogOpen, setDialogOpen] = useState(false)
  const [dialogShorePeriodOpen, setDialogShorePeriodOpen] = useState(false)
  const [filterData, setFilterData] = useState({ ...initFilterData }

  )
  const initShoreBalDataMemo = useMemo(() => {
    return {
      ...initPCPeriodFilterData,
      PCJnlId: msData.pcJournalCombo.find(item => item.IsDf)?.id || null
    }
  }, [msData.pcJournalCombo])

  const getData = useCallback((filter) => {
    if (!filter.PCJnlId) {
      alertError("กรุณาเลือกสมุดบัญชี")
      return
    }
    ax.post(shoreApiNm.getShorePeriodTable, filter).then(value => {
      if (value.data) {
        // lastFilter = filter
        setDataTable(addIdForDataGrid(value.data, "PCPrdId"))
      }
    })
  }, [ax])

  const handleCellClick = useCallback((params) => {
    if (params.field === 'ShowDetail') {
      selectedId = params.id
      setDialogShorePeriodOpen(true)
    } else if(params.field === 'Print'){
      selectedId = params.id
      setDialogOpen(true)
    }
  }, [])
  const handleRowDoubleClick = useCallback((params) => {
    selectedId = params.id
    setDialogShorePeriodOpen(true)
  }, [])

  // const onFinish = useCallback((data) => {
  //   if (data) {
  //     setDataTable(addIdForDataGrid(data, 'PCPrdId'))
  //   } else {
  //     getData(lastFilter)
  //   }
  // }, [getData])

  // const handleAddBalance = useCallback(() => {
  //   selectedId = null
  //   setDialogOpen(true)
  // }, [])

  const onShorePeriodDialogFinish = useCallback(() => {

  }, [])

  const handleAddPCPrd = useCallback(() => {
    selectedId = null
    setDialogShorePeriodOpen(true)
  }, [])

  useEffect(() => {
    getData({ ...initShoreBalDataMemo, })
  }, [ getData, initShoreBalDataMemo])

  return (
    <BoxFC height='100%'>
      <BoxFR>
        <AddButton onClick={handleAddPCPrd}>เพิ่มการปิดยอด</AddButton>
        <Box flex={1} />
        <ComboBox sx={{ width: 150 }} options={msData.pcJournalCombo} label="สมุดบัญชี" required={true}
          selectedId={filterData.PCJnlId} setSelectedId={(id) => setFilterData(o => ({ ...o, PCJnlId: id }))} />
        <DateTimeSelect
          sx={{ width: 180 }}
          label="วันที่ปิดยอด"
          start={filterData.PCPrdTmSt}
          end={filterData.PCPrdTmEn}
          setSelectDate={(st, en) => { setFilterData(o => ({ ...o, PCPrdTmSt: st, PCPrdTmEn: en })) }}
          getData={() => { getData(filterData) }}
        />
        <SearchButton onClick={() => getData(filterData)} />
        <ClearButton onClick={() => setFilterData({ ...clearFilterData })} />
      </BoxFR>
      <Box height={600} >
        <DataGridCellExpand
          hideFooter
          rows={dataTable}
          columns={columns}
          onCellClick={handleCellClick}
          onRowDoubleClick={handleRowDoubleClick}
        />
      </Box>

      <ShorePeriodDialog
        pcPrdId={selectedId}
        dialogOpen={dialogShorePeriodOpen}
        setDialogOpen={setDialogShorePeriodOpen}
        onFinish={onShorePeriodDialogFinish}
        columns={shorePeriodColumns} />

      <PCPeriodDriverDialog 
        pcPrdId={selectedId} 
        dialogOpen={dialogOpen} 
        setDialogOpen={setDialogOpen} />
    </BoxFC>
  );
}

export default PCPeriodDriver