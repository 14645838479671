import { printJobOrder } from "branch/functions/printJobOrder"
import { jobOrderApiNm } from "constants/jobOrderConstant"

const localPrintJobOrder = (data, msData, fName) => {
  const printDataArr = []
  const { jobOrders, shipments, jobs } = data

  for (const sh of shipments) {
    sh.Job = jobs.find(item => item.JobId === sh.JobId)
  }
  for (const jo of jobOrders) {
    jo.Shipment = shipments.find(item => item.ShpmId === jo.ShpmId)
    jo.JobNo = jo.Shipment.JobNo
  }
  for (const jobOrderData of jobOrders) {
    printDataArr.push({ jobOrderData, shipmentData: jobOrderData.Shipment, jobOrdIds: [jobOrderData.JobOrdId], fName: fName })
  }
  printJobOrder({ printDataArr, msData })
}
export const getDataAndPrintJobOrder = async (ax, msData, jobOrdIds, fName) => {
  const value = await ax.post(jobOrderApiNm.getDataForPrint, { JobOrdIds: jobOrdIds })
  if (value.data) {
    localPrintJobOrder(value.data, msData, fName)
  }
}
export const getDataAndPrintJobOrderByShpmId = async (ax, msData, shpmId, fName) => {
  const value = await ax.post(jobOrderApiNm.getDataForPrint, { ShpmId: shpmId })
  if (value.data) {
    localPrintJobOrder(value.data, msData, fName)
  }
}