
import companyLogo from "branch/resource/company-logo.png"

export const printHeader = ({ jsPDFDoc, companyData, startX, startY}) => {
  // console.log("companyData::", companyData)
  let xPos = startX
  let yPos = startY

  const logoWidth = 0.85
  const logoHeight = 0.85
  // const lineHeight = 0.18
  // const img = new Image()
  const defaultHeaderSize = 15;
  const comapanyAddrSize = 11;
  // img.src = companyLogo
  // try {
  //   jsPDFDoc.addImage(img, 'png', xPos, yPos + 0.1, logoWidth, logoHeight)
  //   console.log("imgWidth::", img.width)
  //   xPos += logoWidth + 0.2
  // } catch (e) {
  //   console.log("error::", e)
  // }
  // jsPDFDoc.setFont("THSarabun", "bold")
  // jsPDFDoc.setFontSize(defaultHeaderSize)
  // yPos += 0.2
  // jsPDFDoc.text(companyData.NameEN, xPos, yPos)
  // jsPDFDoc.setFont("THSarabun", "bold")
  // jsPDFDoc.setFontSize(comapanyAddrSize)
  // yPos += lineHeight
  // jsPDFDoc.text(companyData.AddrEN, xPos, yPos)
  // yPos += lineHeight - 0.025
  // yPos += lineHeight - 0.025
  // jsPDFDoc.text(`Tax ID: ${companyData.TaxId}`, xPos, yPos)
  // yPos += lineHeight - 0.025
  // jsPDFDoc.text(`Tel: ${companyData.Tel}${companyData.Fax && ` Fax: ${companyData.Fax}`}`, xPos, yPos)
  // return yPos

  
  const img = new Image()
  img.src = companyLogo

  try {
    jsPDFDoc.addImage(img, 'png', xPos, yPos -0.1, logoWidth, logoHeight)
    console.log("imgWidth::", img.width)
    xPos += logoWidth + 0.2
  } catch (e) {
    console.log("error::", e)
  }
  jsPDFDoc.setFont("THSarabun", "bold")
  jsPDFDoc.setFontSize(defaultHeaderSize)
  jsPDFDoc.text(companyData.NameTH, xPos, yPos)
  jsPDFDoc.setFont("THSarabun", "normal")
  jsPDFDoc.setFontSize(comapanyAddrSize)
  const lineHeightAddr = 0.18
  yPos += lineHeightAddr
  const addrs = jsPDFDoc.splitTextToSize(companyData.AddrTH, 4)
  for(const addr of addrs){
    jsPDFDoc.text(addr, xPos, yPos)
    yPos += lineHeightAddr
  }
  // jsPDFDoc.text(companyData.AddrTH, xPos, yPos)
  // yPos += lineHeight
  jsPDFDoc.text(`เลขประจำตัวผู้เสียภาษี: ${companyData.TaxId}`, xPos, yPos)
  yPos += lineHeightAddr
  jsPDFDoc.text(`โทร: ${companyData.Tel}${companyData.Fax && ` Fax: ${companyData.Fax}`}`, xPos, yPos)
}