import { ArticleRounded } from "@mui/icons-material"
import { IconButton } from "@mui/material"
import { jobOrderColNm } from "constants/jobOrderConstant"
import dayjs from "dayjs"
import { numberFormat } from "utils"
import { pettyCashColNm } from "../PettyCash/constant"
import { shoreColNm } from "constants/shoreConstant"
import { getPOIPlace } from "utils/getPOIPlace"

export const columns = [
  {
    field: 'ShowDetail',
    headerName: '',
    width: 40,
    align: 'center',
    renderCell: (params) => {
      return (
        <IconButton size="small" >
          <ArticleRounded color="primary" />
        </IconButton>
      )
    }
  },
  {
    field: "No",
    headerName: "No.",
    width: 60,
    align: "center",
  },
  {
    field: "PCJnlNm",
    headerName: "บัญชี",
    width: 80,
  },
  {
    field: "DocDte",
    headerName: "วันที่ทำชอร์",
    width: 100,
    type: 'date',
    valueGetter: (params) => params.value && dayjs(params.value),
    valueFormatter: (params) => params.value ? params.value.format("DD/MM/YYYY") : "-",
  },
  {
    field: "ContNo",
    headerName: jobOrderColNm.ContNo,
    width: 120
  },
  {
    field: "ContSizeFull",
    headerName: jobOrderColNm.ContSizeFull,
    width: 60
  },
  {
    field: "RtnPlc",
    headerName: jobOrderColNm.RtnPlc,
    valueGetter: ({row})=> row.TagData?.RtnPlc,
    width: 100,
  },
  {
    field: "Vssl",
    headerName: jobOrderColNm.Vssl,
    valueGetter: ({row})=> row.TagData?.Vssl,
    width: 120,
  },
  {
    field: "Voy",
    headerName: jobOrderColNm.Voy,
    valueGetter: ({row})=> row.TagData?.Voy,
    width: 80,
  },
  {
    field: "Amnt",
    headerName: jobOrderColNm.Amnt,
    align: "right",
    valueFormatter: (params) => params.value ? numberFormat(+params.value) : "0.00",
    width: 150,
  },
  {
    field: "JobSNm",
    headerName: jobOrderColNm.JobSNm,
    width: 120,
  },
  {
    field: "AptTm",
    headerName: jobOrderColNm.AptTm,
    width: 130,
    type: 'date',
    valueGetter: (params) => params.value && dayjs(params.value),
    valueFormatter: (params) => params.value ? params.value.format("DD/MM HH:mm") : "-",
  },
  {
    field: "Rmk",
    headerName: jobOrderColNm.Rmk,
    minWidth: 150,
    flex: 1
  },
]

export const shoreForBalSelectColumns = [
  {
    field: 'ShowDetail',
    headerName: '',
    width: 40,
    align: 'center',
    renderCell: (params) => {
      return params.id === 0 ? null : (
        <IconButton size="small" >
          <ArticleRounded color="primary" />
        </IconButton>
      )
    }
  },
  {
    field: "No",
    headerName: "No.",
    width: 50,
    align: "center"
  },
  {
    field: "PayTm",
    headerName: pettyCashColNm.PayTm,
    width: 110,
    type: "dateTime",
    valueFormatter: (params) => {
      return params.value ? dayjs(params.value).format("DD/MM HH:mm") : "-";
    },
  },
  {
    field: "ExpNm",
    headerName: pettyCashColNm.ExpNm,
    flex: 1,
    valueGetter: ({ row, value }) => !value ? "-" : `${row.PrPCTrnsId ? row.InOutTyp === "I" ? "(รับเงิน)" : "(เงินเพิ่ม)" : ""}${row.ExpNm}`
  },
  {
    field: "Loc",
    headerName: "สถานที่",
    width: 150,
  },
  {
    field: "RtnPlc",
    headerName: "ท่าคืน",
    width: 80,
  },
  {
    field: "ContNo",
    headerName: "หมายเลขตู้",
    width: 120,
  },
  {
    field: "ContSizeFull",
    headerName: "ขนาดตู้",
    width: 60,
  },
  {
    field: "Amnt",
    headerName: pettyCashColNm.Amnt,
    width: 90,
    align: "right",
  },
  {
    field: "WhtPrct",
    headerName: pettyCashColNm.WhtPrct,
    width: 90,
    valueGetter: ({value}) =>  `${value||0}%` ,
    align: "right",
  },
  {
    field: "OutAmnt",
    headerName: "เงินจ่าย",
    width: 100,
    align: "right",
    type: "number",
    valueFormatter: (params) => params.value ? numberFormat(params.value) : "",
  },
  {
    field: "InAmnt",
    headerName: "เงินรับ",
    width: 100,
    align: "right",
    type: "number",
    valueFormatter: (params) => params.value ? numberFormat(params.value) : "",
  },
  {
    field: "AdmNNm",
    headerName: pettyCashColNm.AdmNNm,
    width: 100,
  },
]

export const shoreBalSummary = [
  {
    field: "No",
    headerName: "No.",
    width: 50,
    align: "center"
  },
  {
    field: "ExpNm",
    headerName: pettyCashColNm.ExpNm,
    flex: 1,
    valueGetter: ({ row, value }) => !value ? "-" : `${row.PrPCTrnsId ? row.InOutTyp === "I" ? "(รับเงิน)" : "(เงินเพิ่ม)" : ""}${row.ExpNm}`
  },
  {
    field: "Qty",
    headerName: "Qty",
    width: 50,
    align: "center"
  },
  {
    field: "OutAmnt",
    headerName: "เงินจ่าย",
    width: 120,
    align: "right",
    type: "number",
    valueFormatter: (params) => params.value ? numberFormat(params.value) : "",
  },
  {
    field: "InAmnt",
    headerName: "เงินรับ",
    width: 120,
    align: "right",
    type: "number",
    valueFormatter: (params) => params.value ? numberFormat(params.value) : "",
  },
  {
    field: "TotalOutAmnt",
    headerName: "รวมเงินจ่าย",
    width: 120,
    align: "right",
    type: "number",
    valueGetter: ({id, row, value}) => id===0?value: (+(row.OutAmnt||0) * +row.Qty),
    valueFormatter: (params) => params.value ? numberFormat(params.value) : "",
  },
  {
    field: "TotalInAmnt",
    headerName: "รวมเงินรับ",
    width: 120,
    align: "right",
    type: "number",
    valueGetter: ({id, row, value}) => id===0?value: (+(row.InAmnt||0) * +row.Qty),
    valueFormatter: (params) => params.value ? numberFormat(params.value) : "",
  },
]
export const printShoreColumns = [
  {
    field: "No",
    headerName: "No.",
    width: 60,
    align: "center",
  },
  {
    field: "RcptNm",
    headerName: "ชื่อใบเสร็จ",
    width: 120,
  },
  {
    field: "RcptAddr",
    headerName: "ที่อยุ่ใบเสร็จ",
    width: 130,
  },
  {
    field: "Agnt",
    headerName: "Agent",
    width: 80,
  },
  {
    field: "Bkg",
    headerName: "Booking",
    width: 150,
  },
  {
    field: "AptTm",
    headerName: "เวลานัด",
    width: 100,
    type: 'date',
    valueGetter: (params) => params.value && dayjs(params.value),
    valueFormatter: (params)=> params.value? params.value.format("DD/MM HH:mm"): "-",
  },
  {
    field: "RtnDteEn",
    headerName: "เวลาปิดรับตู้",
    width: 100,
    type: 'date',
    valueGetter: (params) => params.value && dayjs(params.value),
    valueFormatter: (params)=> params.value? params.value.format("DD/MM HH:mm"): "-",
  },
  {
    field: "ContNo",
    headerName: "หมายเลขตู้",
    width: 120
  },
  {
    field: "ContSizeFull",
    headerName: "ขนาดตู้",
    width: 70,
  },
  {
    field: "RtnPlc",
    headerName: shoreColNm.RtnPlc,    
    valueGetter: (params) => getPOIPlace(params.row, "RTN"),
    width: 100,
  },
  {
    field: "Vssl",
    headerName: shoreColNm.Vssl,
    width: 120,
  },
  {
    field: "Voy",
    headerName: shoreColNm.Voy,
    width: 100,
  }
]


export const printColumnHeader = [
  {
    id: "No",
    name: "No",
    prompt: "No.",
    width: 0.4,
    align: "center",
    padding: 0,
    separateRow: "-"
  },
  {
    id: "RcptNm",
    name: "RcptNm",
    prompt: "ชื่อใบเสร็จ",
    width: 1.1,
    align: "center",
    padding: 0,
    separateRow: "-"
  },
  {
    id: "RcptAddr",
    name: "RcptAddr",
    prompt: "ที่อยุ่ใบเสร็จ",
    width: 1.5,
    align: "center",
    padding: 0,
    separateRow: "-"
  },
  {
    id: "Agnt",
    name: "Agnt",
    prompt: "Agent",
    width: 1,
    align: "center",
    padding: 0,
    separateRow: "-"
  },
  {
    id: "Bkg",
    name: "Bkg",
    prompt: "Booking",
    width: 1.7,
    align: "center",
    padding: 0,
    separateRow: "-"
  },
  {
    id: "ContNo",
    name: "ContNo",
    prompt: "หมายเลขตู้",
    width: 1.3,
    align: "center",
    padding: 0,
    separateRow: "-"
  },
  {
    id: "ContSizeFull",
    name: "ContSizeFull",
    prompt: "ขนาดตู้",
    width: 1,
    align: "center",
    padding: 0,
    separateRow: "-"
  },
  {
    id: "RtnPlc",
    name: "RtnPlc",
    prompt: "สถานที่คืน",
    width: 1.2,
    align: "center",
    padding: 0,
    separateRow: "-"
  }
]

export const shorePeriodColumns = [
  {
    field: 'ShowDetail',
    headerName: '',
    width: 40,
    align: 'center',
    renderCell: (params) => {
    return (
      <IconButton size="small" >
      <ArticleRounded color="primary"/>
      </IconButton>
    )}
  },
  {
    field: "No",
    headerName: "No.",
    width: 50,
    align: 'center',
  },
  {
    field: "PCJnlNm",
    headerName: "บัญชี",
    width: 80,
  },
  {
    field: "PCPrdTm",
    headerName: "เวลาสรุปยอด",
    width: 140,
    type: "dateTime",
    valueFormatter: (params) => {
      return params.value ? dayjs(params.value).format("DD/MM/YYYY HH:mm") : "-";
    },
  },
  {
    field: "NumAll",
    headerName: "จำนวนรายการ",
    width: 90,
    align: 'center',
  },
  {
    field: "Num20",
    headerName: "จำนวนตุ้ 20",
    width: 90,
    align: 'center',
  },
  {
    field: "Amnt20",
    headerName: "ยอดเงินตู้ 20",
    width: 100,
    valueFormatter: ({value})=> value?numberFormat(+value): "0",
    align: 'right',
  },
  {
    field: "Num40",
    headerName: "จำนวนตุ้ 40",
    width: 90,
    align: 'center',
  },
  {
    field: "Amnt40",
    headerName: "ยอดเงินตู้ 40",
    width: 100,
    valueFormatter: ({value})=> value?numberFormat(+value): "0",
    align: 'right',
  },
  {
    field: "Num45",
    headerName: "จำนวนตุ้ 45",
    width: 90,
    align: 'center',
  },
  {
    field: "Amnt45",
    headerName: "ยอดเงินตู้ 45",
    width: 100,
    valueFormatter: ({value})=> value?numberFormat(+value): "0",
    align: 'right',
  },
  {
    field: "SumOut",
    headerName: "รวมยอดเงิน จ่าย",
    width: 110,
    valueFormatter: ({value})=> value?numberFormat(+value): "0",
    align: 'right',
  },
  {
    field: "SumIn",
    headerName: "รวมยอดเงิน รับ",
    width: 110,
    valueFormatter: ({value})=> value?numberFormat(+value): "0",
    align: 'right',
  },
  {
    field: "Bal",
    headerName: "ยอดคงเหลือ",
    width: 110,
    valueFormatter: ({value})=> value?numberFormat(+value): "0",
    align: 'right',
  },
  {
    field: "Rmk",
    headerName: "หมายเหตุ",
    flex: 1,
    minWidth: 120,
  },
]