import { jobOrderColNm } from "./jobOrderConstant"

export const shoreApiNm = {
  getShore: "/shore/getShore",
  getShoreTableV2: "/shore/getShoreTableV2",
  getShorePeriodTable: "/shore/getShorePeriodTable",
  checkShoreV2: "/shore/checkShoreV2",
  getPrintShore: "/shore/getPrintShore",
  getPrintShoreV2: "/shore/getPrintShoreV2",
  insertShore: "/shore/insertShore",
  updateShore: "/shore/updateShore",
  deleteShore: "/shore/deleteShore"
}


export const shoreColNm =  {
  JobNo: jobOrderColNm.JobNo,
  ContNo: jobOrderColNm.ContNo,
  RtnPlc: jobOrderColNm.RtnPlc,
  Vssl: jobOrderColNm.Vssl,
  Voy: jobOrderColNm.Voy,
  JobSNm: jobOrderColNm.JobSNm,
  AptTm: jobOrderColNm.AptTm,
  JobOrdIds: jobOrderColNm.JobOrdId,
  Amnt: "ค่าชอ",
  RcptNo: "ใบเสร็จชอ",
  ShorDte: "วันที่ทำชอ",
  Rmk: "หมายเหตุ",
  ModDte: "วันที่แก้ไข",
  ModAcc: "ผู้แก้ไข",
}