import dayjs from "dayjs"

export const initAddData  = {
  JobOrdId: "",
  RtnPOIId: null,
  ContNo: "",
  JobSNm: "", 
  CusSNm: "",
  ContSizeFull: "",
  AptTm: "",
  RtnPlc: "",
  RtnPOIIdJobOrd: null,
  DocNo: "",
  DocDte: dayjs(),
  Amnt: "",
  Vssl: "",
  Voy: "",
  VsslJobOrd:"", 
  VoyJobOrd: "",
  Rmk: "",
  Dscp: "",
  CanInsert: false,
  IsCost: 0,
  IsOwnRcpt: 0,
  VatPrct: 0,
  WhtPrct: 0,
  PCJnlId: null,
}

export const initShoreBalData = {
  PCPrdTm: dayjs(),
  PCJnlId: null,
  PCJnlNm: "",
  PrevBal: 0,
  RecvAmnt: 0,
  ExpAmnt: 0,
  NewBal: 0,
  Rmk: "",
}
export const initDialogData = {
  DocNo: "",
  DocDte: null,
  Vssl: "",
  Voy: "",
  RtnPOIId: null,
  Amnt: "",
  Rmk: "",
  PCTrnsId: null,
}
export const clearFilterData = {
  DocDteSt: null,
  DocDteEn: null,
  PCJnlId: null,
  ContNo: "",
  JobId: null,
}
export const initFilterData = {
  ...clearFilterData,
  DocDteSt: dayjs().format("YYYY-MM-DD 00:00:00"),
  DocDteEn: dayjs().format("YYYY-MM-DD 23:59:59"),
}

export const clearShorePeriodFilterData = {
  PCPrdTmSt: null,
  PCPrdTmEn: null,
  PCJnlId: null
}
export const initShorePeriodFilterData = {
  ...clearShorePeriodFilterData,
  PCPrdTmSt: dayjs().add(-7, 'day').format("YYYY-MM-DD 00:00:00"),
  PCPrdTmEn: dayjs().format("YYYY-MM-DD 23:59:59")
}