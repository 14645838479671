import { Box, Button, CircularProgress, FormControl, FormControlLabel, FormLabel, InputAdornment, MenuItem, Radio, RadioGroup, TextField, Typography } from "@mui/material"
import { BoxFC, BoxFR } from "components/BoxCustom"
import DateTimePickerCustomTime from "components/DateTimePickerCustomTime"
import { GlobalStateContext } from "contexts/GlobalStateContext"
import { UserContext } from "contexts/UserContext"
import React, { useCallback, useContext, useEffect, useState } from "react"
import { pettyCashApiNm, pettyCashColNm } from "./constant"
import ComboBox from "components/ComboBox"
import { alertError, alertSuccess } from "components/Alert"
import { CancelRounded, CheckCircleRounded, CloseRounded, PrintRounded, SaveRounded } from "@mui/icons-material"
import { expensePOIApiNm } from "pages/Menu4/ExpensePOI/constant"
import { red, green, grey } from "@mui/material/colors"
import { expenseJobOrderColNm } from "constants/expenseJobOrderConstant"
import { bahtProps, percentProps } from "utils/bahtProps"
import { jobOrderApiNm } from "constants/jobOrderConstant"
import { printWht } from "./printWht"
import { numberFormat, selectOnFocus } from "utils"
import { toBahtText } from "utils/toBahtText"
import { printWhtNoAgent } from "./printWhtNoAgent"
import { branchNoText } from "utils/branchNoText"
import ExpensePOIDialog from "pages/Menu4/ExpensePOI/ExpensePOIDialog"

const initWhtPrintData = {
  IsOwnWht: 0,
  WhtTyp: 5,
  WhtTypOthTxt: "",
}

let isPreventUpdateAmnt = false

let initExpensePOIData = null

let suggessPOIIds =null

const PayComponent = ({ dialogOpen, dialogData, setDialogData, isItemDialog, isRequriedUsrAccId, usrAccId, jobOrdId, isHideJobOrdId }) => {
  const { user } = useContext(UserContext)
  const { msData, ax } = useContext(GlobalStateContext)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingUntPr, setIsLoadingUntPr] = useState(false)
  const [showPrintWht, setShowPrintWht] = useState(false)
  const [whtPrintData, setWhtPrintData] = useState({ ...initWhtPrintData })
  const [dialogExpensePOIOpen, setDialogExpensePOIOpen] = useState(false)

  const whtPrintTextFieldProps = useCallback((name) => ({
    size: "small",
    value: whtPrintData[name],
    onChange: (e) => { setWhtPrintData(o => ({ ...o, [name]: e.target.value })) }
  }), [whtPrintData])

  const textFieldProps = useCallback((name, isRequired) => ({
    fullWidth: true,
    required: isRequired,
    label: pettyCashColNm[name],
    size: "small",
    error: isRequired && !dialogData[name],
    value: dialogData[name] ?? "",
    onChange: (e) => setDialogData(o => ({ ...o, [name]: e.target.value })),
  }), [dialogData, setDialogData])

  const dateTimePickerProp = useCallback((name, isRequired) => ({
    label: pettyCashColNm[name],
    inputFormat: "DD/MM/YYYY HH:mm",
    mask: "__/__/____ __:__",
    value: dialogData[name],
    onChange: (newValue) => { setDialogData(o => ({ ...o, [name]: newValue })) },
    renderInput: (params) => <TextField size="small" {...params} fullWidth required={isRequired} error={isRequired && !dialogData[name]} />,
  }), [dialogData, setDialogData])

  const comboboxProps = useCallback((nameId, nameNm, options) => ({
    label: pettyCashColNm[nameId],
    sx: { width: "100%" },
    options: options,
    selectedId: dialogData[nameId],
    setSelectedId: (id) => setDialogData(o => ({ ...o, [nameId]: id, [nameNm]: options.find(option => option.id === id)?.label })),
  }), [dialogData, setDialogData])

  const setUntPrByJobOrdIdExpId = useCallback((jobOrdId, expId) => {
    console.log("setUntPrByJobOrdIdExpId", jobOrdId, expId)
    if (isPreventUpdateAmnt) return

    if (dialogData.PCPrdId) return

    if (expId) {

      setIsLoadingUntPr(true)
      ax.post(expensePOIApiNm.getUntPrByJobOrdIdExpId, { JobOrdId: jobOrdId || null, ExpId: expId }, false).then(value => {
        setIsLoadingUntPr(false)
        if (value.data && value.data.length === 1) {
          setDialogData(o => ({
            ...o,
            Amnt: value.data[0].UntPr,
            VatPrct: value.data[0].VatPrct,
            WhtPrct: value.data[0].WhtPrct,
            IsOwnRcpt: value.data[0].IsOwnRcpt,
            IsCost: o.InOutTyp === "O" ? value.data[0].IsCost : o.IsCost,
          }))
        } else {
          setDialogData(o => ({
            ...o,
            Amnt: "",
            VatPrct: 0,
            WhtPrct: 0,
            IsOwnRcpt: 0,
            IsCost: o.InOutTyp === "O" ? 0 : o.IsCost,
          }))
        }
      })
    }
  }, [ax, setDialogData, dialogData.PCPrdId])

  const handleJobOrdIdBlur = useCallback((e) => {
    if (e.target.value === "") {
      setDialogData(o => ({
        ...o,
        LocText: "",
        isJobOrdIdCorrect: null,
      }))
      return
    }
    setIsLoading(true)
    ax.post(pettyCashApiNm.getJobOrderLocation, { JobOrdId: e.target.value }, false).then(value => {
      setIsLoading(false)
      if (value.data) {
        if (value.data.length !== 1) {
          setDialogData(o => ({
            ...o,
            LocText: "",
            isJobOrdIdCorrect: false,
          }))
          alertError("ไม่พบข้อมูล เลขที่ใบงานนี้")
          return
        } else {
          const data = value.data[0]
          setDialogData(o => ({
            ...o,
            ...data,
            isJobOrdIdCorrect: true,
            LocText: `(${data.ContSizeFull || ""})${data.TakePlc || ""}-${data.Loc || ""}-${data.RtnPlc || ""}`
          }))
          if (dialogData.ExpId) {
            setUntPrByJobOrdIdExpId(e.target.value, dialogData.ExpId)
          }
        }
      }
    })
  }, [ax, setDialogData, dialogData.ExpId, setUntPrByJobOrdIdExpId])


  const handleExpChange = useCallback((id) => {
    console.log("handleExpChange", id)
    setDialogData(o => {
      setUntPrByJobOrdIdExpId(o.JobOrdId, id)
      return { ...o, ExpId: id }
    })
  }, [setDialogData, setUntPrByJobOrdIdExpId])

  const calculatePayAmnt = useCallback(() => {
    isPreventUpdateAmnt = true

    setDialogData(o => {
      const amnt = +(o.Amnt || 0)
      const vatPrct = +(o.VatPrct || 0)
      const whtPrct = +(o.WhtPrct || 0)
      const payAmnt = amnt / (1 + vatPrct / 100) * (1 - whtPrct / 100) + amnt / (1 + vatPrct / 100) * vatPrct / 100
      return { ...o, PayAmnt: payAmnt.toFixed(2) }
    })
  }, [setDialogData])

  const handlePrintWht = useCallback(() => {
    if (dialogData.JobOrdId) {
      ax.post(jobOrderApiNm.getDataForPrintWht, { JobOrdId: dialogData.JobOrdId }).then(value => {
        if (value.data && value.data.length === 1) {
          const data = value.data[0]
          let receiver = { name: "", addr: "", taxId: "" }
          if (data.TakePOIData?.TaxId && data.TakePOIData?.POITypId === 5) {
            receiver = {
              name: data.TakePOIData.POIName,
              addr: data.TakePOIData.Addr,
              taxId: data.TakePOIData.TaxId,
              brchNo: data.TakePOIData.BrchNo
            }
          } else if (data.RtnPOIData?.TaxId && data.RtnPOIData?.POITypId === 5) {
            receiver = {
              name: data.RtnPOIData.POIName,
              addr: data.RtnPOIData.Addr,
              taxId: data.RtnPOIData.TaxId,
              brchNo: data.RtnPOIData.BrchNo
            }
          } else if (data.LocPOIData?.TaxId && data.LocPOIData?.POITypId === 5) {
            receiver = {
              name: data.LocPOIData.POIName,
              addr: data.LocPOIData.Addr,
              taxId: data.LocPOIData.TaxId,
              brchNo: data.LocPOIData.BrchNo
            }
          }
          if (receiver.taxId !== "") {
            receiver.addr = `${receiver.addr} ${receiver.brchNo >= 0 ? branchNoText(receiver.name, receiver.brchNo) : ""}`
          }
          const comData = msData.serverData.CompanyData
          const amnt = (+dialogData.Amnt) / (1 + +dialogData.VatPrct / 100)
          const wht = amnt * +dialogData.WhtPrct / 100
          const jobData = {
            name: data.JobData.RcptNm,
            addr: `${data.JobData.RcptAddr} ${data.JobData.RcptBrchNo >= 0 ? branchNoText(data.JobData.RcptNm, data.JobData.RcptBrchNo) : ""}`,
            taxId: data.JobData.RcptTaxId
          }
          const ownAddr = { name: comData.NameTH, addr: comData.AddrTH, taxId: comData.TaxId }
          const whtData = {
            receiver: receiver,
            amnt: numberFormat(amnt),
            wht: numberFormat(wht),
            whtText: toBahtText(wht),
          }
          if (whtPrintData.IsOwnWht) {
            whtData.payer = ownAddr
            whtData.agent = { name: "", addr: "", taxId: "" }
            printWhtNoAgent({ whtData })
          } else {
            whtData.payer = jobData
            whtData.agent = ownAddr
            printWht({ whtData })
          }
        }
      })
    }
  }, [ax, dialogData, whtPrintData, msData.serverData.CompanyData])

  const handleSaveExpensePOI = useCallback(async () => {
    initExpensePOIData = {
      ExpPOIId: null,
      IsCost: dialogData.IsCost,
      ExpId: dialogData.ExpId,
      VatPrct: dialogData.VatPrct,
      WhtPrct: dialogData.WhtPrct,
      UntPr: dialogData.Amnt,
      IsOwnRcpt: dialogData.IsOwnRcpt,
      Prty: 50,
      JobId: null,
      CusId: null,
      POIId: null,
      ContSize: "",
      ContTyp: "",
      ShpmTypId: null,
      TlrTypId: null,
      Rmk: ""
    }
    suggessPOIIds = null

    if (dialogData.JobOrdId) {
      const postData = {
        JobOrdId: dialogData.JobOrdId
      }
      const res = await ax.post(jobOrderApiNm.getJobOrder, postData)
      if (res.data && res.data.length > 0) {
        const data = res.data[0]
        initExpensePOIData = {
          ...initExpensePOIData,
          JobId: data.Shipment.JobId,
          CusId: data.Shipment.CusId,
          POIId: data.LocPOIId,
          ContSize: data.ContSize,
          ContTyp: data.ContTyp,
          ShpmTypId: data.ShpmTypId,
          TlrTypId: data.TlrTypId,
        }
        if(data.TakePOIId || data.LocPOIId || data.RtnPOIId){
          suggessPOIIds = []
          data.TakePOIId && suggessPOIIds.push(data.TakePOIId)
          data.LocPOIId && suggessPOIIds.push(data.LocPOIId)
          data.RtnPOIId && suggessPOIIds.push(data.RtnPOIId)
        }
      }
    }

    setDialogExpensePOIOpen(true)
  }, [dialogData, ax])

  const onExpensePOIFinish = useCallback((data) => {
    if(data){
      alertSuccess("บันทึกข้อมูลเรียบร้อยแล้ว")
    }
  }, [])
  useEffect(() => {
    isPreventUpdateAmnt = false
  }, [dialogOpen])

  console.log("PayComponent", dialogData)
  return (
    <BoxFC width={400} sx={{ gap: 1 }}>
      <BoxFR width="100%">
        {!isItemDialog &&
          <ComboBox {...comboboxProps("UsrAccId", "UsrNNm", msData.accountAllCombo)}
            required={isRequriedUsrAccId ?? false}
            selectedId={dialogData.UsrAccId} />
        }
        <DateTimePickerCustomTime {...dateTimePickerProp("PayTm", true)} disabled={dialogData.PCPrdId ? true : false} />
      </BoxFR>
      {isHideJobOrdId ? null :
        <BoxFR>
          <TextField {...textFieldProps("JobOrdId")} disabled={jobOrdId ? true : false}
            onBlur={handleJobOrdIdBlur} type="number"
            onChange={(e) => setDialogData(o => ({ ...o, JobOrdId: e.target.value || null }))}
            InputProps={{
              endAdornment:
                <InputAdornment position="end">
                  {isLoading ? <CircularProgress size={20} />
                    : !dialogData.JobOrdId || dialogData.isJobOrdIdCorrect === null ? null
                      : dialogData.isJobOrdIdCorrect ? <CheckCircleRounded color="success" /> : <CancelRounded color="error" />}
                </InputAdornment>
            }} />
          <TextField {...textFieldProps("LocText")} onChange={null}
            inputProps={{ style: { fontSize: "0.8em" } }} />
        </BoxFR>
      }

      <BoxFR>
        <ComboBox {...comboboxProps("ExpId", "ExpNm", msData.expOprCombo)}
          setSelectedId={handleExpChange}
          required={true} />
        <TextField {...textFieldProps("Amnt", true)} type="number" disabled={isLoadingUntPr || dialogData.PCPrdId ? true : false}
          onBlur={calculatePayAmnt}
          onFocus={selectOnFocus}
          inputProps={{ style: { textAlign: "right" } }}
          InputProps={{
            endAdornment:
              <InputAdornment position="end">
                {isLoadingUntPr ? <CircularProgress size={20} /> : "บาท"}
              </InputAdornment>
          }} />
      </BoxFR>
      <BoxFR>
        <TextField {...textFieldProps("VatPrct")} type="number" {...percentProps} onBlur={calculatePayAmnt} onFocus={selectOnFocus} />
        <TextField {...textFieldProps("WhtPrct")} type="number" {...percentProps}
          disabled={dialogData.PCPrdId ? true : false}
          onBlur={calculatePayAmnt}
          onFocus={selectOnFocus} />
      </BoxFR>
      <BoxFR>
        <TextField {...textFieldProps("IsCost")} label="การวางบิลลูกค้า" disabled={dialogData.InOutTyp === "I"}
          select sx={{ bgcolor: dialogData.InOutTyp === "I" ? grey[100] : dialogData.IsCost === 1 ? red[50] : green[50] }}>
          <MenuItem value={0}>วางบิลได้(รายได้)</MenuItem>
          <MenuItem value={1}>วางบิลไม่ได้(ต้นทุน)</MenuItem>
        </TextField>
        <TextField {...textFieldProps("PayAmnt")} type="number"{...bahtProps} disabled />
      </BoxFR>
      {isItemDialog &&
        <BoxFR>
          <TextField {...textFieldProps("DocNo")} />
          <DateTimePickerCustomTime {...dateTimePickerProp("DocDte")} inputFormat={"DD/MM/YYYY"} mask={"__/__/____"} />
        </BoxFR>
      }
      <TextField {...textFieldProps("Dscp")} />
      {
        !dialogData.IsCost &&

        <FormControl component="fieldset">
          <FormLabel component="legend">{expenseJobOrderColNm.IsOwnRcpt}</FormLabel>
          <RadioGroup row name='IsOwnRcpt' value={dialogData.IsOwnRcpt}
            onChange={(e) => { setDialogData((oldState) => ({ ...oldState, IsOwnRcpt: e.target.value })) }}>
            <FormControlLabel value={1} control={<Radio />} label="ใบเสร็จบริษัท" />
            <FormControlLabel value={0} control={<Radio />} label="ใบเสร็จลูกค้า" />
          </RadioGroup>
        </FormControl>
      }
      <BoxFR>
        {!isItemDialog &&
          <ComboBox {...comboboxProps("PCJnlId", "PCJnlNm", msData.pcJournalCombo)} required={true}
            disabled={dialogData.PCPrdId ? true : false} />
        }
        <TextField {...textFieldProps("CtrlCd")} />
      </BoxFR>
      <TextField {...textFieldProps("Rmk")} />
      <BoxFR>
        {!showPrintWht ?
          <Button variant="contained" onClick={e => { setShowPrintWht(o => !o) }} disabled={dialogData.JobOrdId ? false : true}>
            <PrintRounded sx={{ mr: 1 }} />พิมพ์ใบหัก ณ ที่จ่าย</Button>
          : <BoxFR>
            <TextField {...whtPrintTextFieldProps("IsOwnWht")} label="ผู้หัก ณ ที่จ่าย" select >
              <MenuItem value={0}>กระทำการแทน</MenuItem>
              <MenuItem value={1}>บริษัททำการหัก</MenuItem>
            </TextField>
            <TextField {...whtPrintTextFieldProps("WhtTyp")} label="ประเภทหัก" select >
              <MenuItem value={5}>ค่าบริการ</MenuItem>
              {/* <MenuItem value={0}>อื่นๆ</MenuItem> */}
            </TextField>
            {/* <TextField {...whtPrintTextFieldProps("WhtTypOthTxt")} label="รายละเอียดอื่นๆ" 
            disabled={whtPrintData.WhtTyp === 5? true:false}/> */}
            <Button variant="contained" onClick={handlePrintWht}><PrintRounded sx={{ mr: 1 }} />พิมพ์</Button>
            <Button variant="contained" color="inherit" sx={{ minWidth: 0, px: 0, }}
              onClick={e => { setShowPrintWht(o => !o) }}><CloseRounded /></Button>
          </BoxFR>
        }
      </BoxFR>
      <BoxFR>
        <Typography ><strong>ผู้ทำรายการ: </strong> {user.NName} </Typography>
        <Box flex={1} />
        <Button size="small" onClick={handleSaveExpensePOI}><SaveRounded sx={{ mr: 1 }} /> บันทึกข้อมูลใน 4.3</Button>
      </BoxFR>
      <ExpensePOIDialog
        dialogOpen={dialogExpensePOIOpen}
        setDialogOpen={setDialogExpensePOIOpen}
        selectedId={null}
        lastFilter={null}
        onFinish={onExpensePOIFinish}
        initData={initExpensePOIData}
        suggessPOIIds={suggessPOIIds}
      />
    </BoxFC>
  )
}

export default PayComponent