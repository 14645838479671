import { Route } from "react-router-dom/cjs/react-router-dom.min"
import JobOrderAllDetail from "./ContainerAllDetail/JobOrderAllDetail"
import TruckIncomeSummary from "./TruckIncomSummary/TruckIncomeSummary"
import JobPlaning from "./JobPlaning/JobPlaning"
import MapMT from "./MapMT/MapMT"
import JobPlaningV2 from "./JobPlaningV2/JobPlaningV2"
import InvoiceReview from "./InvoiceReview/InvoiceReview"
import PCPeriodDriver from "./PCPeriodDriver/PCPeriodDriver"
import DriverSalary from "./DriverSalary/DriverSalary"
import DriverAdvancePay from "./DriverAdvancePay/DriverAdvancePay"
import DriverTripPayment from "./DriverTripPayment/DriverTripPayment"


// export const branchApiNm = {
//   getJobOrderAllDetail: "/branch/getJobOrderAllDetail",
//   getTruckIncomeSummary: "/branch/getTruckIncomeSummary",
// }

export const extraPageName = {
  JobPlaning: "การวางแผนงาน",
  JobPlaningV2: "การวางแผนงาน(V2)",
  JobOrderAllDetail: "รายละเอียดงานและคชจ.",
  TruckIncomeSummaryB: "รายงานรายได้แยกตามรถ",
  MapMT: "แผนที่",
  InvoiceReview: "ออกใบแจ้งหนี้มนตรี",
  PCPeriodDriver: "สรุปยอดบัตรเงินสด/โอน",
  DriverTripPayment: "ค่าเที่ยว พนักงานขับรถ",
  DriverSalary: "เงินเดือนพนักงานขับรถ",
  DriverAdvancePay: "เงินเบิกล่วงหน้า",
}
export const extraPageMenu = [
  { page: "JobPlaning", num: "8.1", menuName: extraPageName.JobPlaning, prNum: 8 },
  { page: "JobPlaningV2", num: "8.1.1", menuName: extraPageName.JobPlaningV2, prNum: 8 },
  { page: "JobOrderAllDetail", num: "8.2", menuName: extraPageName.JobOrderAllDetail, prNum: 8,  permission: "EXE;FIN" },
  { page: "TruckIncomeSummaryB", num: "8.3", menuName: extraPageName.TruckIncomeSummaryB, prNum: 8,  permission: "EXE;FIN" },
  { page: "MapMT", num: "8.4", menuName: extraPageName.MapMT, prNum: 8 },
  { page: "PCPeriodDriver", num: "8.5", menuName: extraPageName.PCPeriodDriver, prNum: 8 },
  { page: "InvoiceReview", num: "8.6", menuName: extraPageName.InvoiceReview, prNum: 8 },
  { page: "DriverTripPayment", num: "8.7", menuName: extraPageName.DriverTripPayment, prNum: 8, permission: "EXE;FIN"},
  { page: "DriverAdvancePay", num: "8.8", menuName: extraPageName.DriverAdvancePay, prNum: 8},
  { page: "DriverSalary", num: "8.9", menuName: extraPageName.DriverSalary, prNum: 8, permission: "EXE;FIN"},
]

export const extraRoute = (path)=> [
  <Route key={"JobOrderAllDetail"} path={`${path}/JobOrderAllDetail`} ><JobOrderAllDetail /> </Route>,
  <Route key={"TruckIncomeSummaryB"} path={`${path}/TruckIncomeSummaryB`} ><TruckIncomeSummary /> </Route>,
  <Route key={"JobPlaning"} path={`${path}/JobPlaning`} ><JobPlaning /> </Route>,
  <Route key={"JobPlaningV2"} path={`${path}/JobPlaningV2`} ><JobPlaningV2 /> </Route>,
  <Route key={"MapMT"} path={`${path}/MapMt`} ><MapMT /> </Route>,
  <Route key={"InvoiceReview"} path={`${path}/InvoiceReview`} ><InvoiceReview /> </Route>,
  <Route key={"PCPeriodDriver"} path={`${path}/PCPeriodDriver`} ><PCPeriodDriver /> </Route>,
  <Route key={"DriverTripPayment"} path={`${path}/DriverTripPayment`} ><DriverTripPayment /> </Route>,
  <Route key={"DriverAdvancePay"} path={`${path}/DriverAdvancePay`} ><DriverAdvancePay /> </Route>,
  <Route key={"DriverSalary"} path={`${path}/DriverSalary`} ><DriverSalary /> </Route>,
]

export const extraMainRoute = (path)=> []
