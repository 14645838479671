import { BoxFC, BoxFR } from "components/BoxCustom";
import { GlobalStateContext } from "contexts/GlobalStateContext";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { initFilterData, clearFilterData } from "./initData"
import DateTimeSelect from "components/DateTimeSelect";
import ComboBox from "components/ComboBox";
import { repairOrderApiNm } from "./constant";
import SearchButton from "components/buttons/SearchButton";
import ClearButton from "components/buttons/ClearButton";
import { columns } from "./columns";
import { Box, MenuItem, TextField } from "@mui/material";
import DataGridCellExpand from "components/DataGridCellExpand/DataGridCellExpand";
import AddButton from "components/buttons/AddButton";
import RepairOrderDialog from "./RepairOrderDialog";
import { addIdForDataGrid } from "utils";

let selectedId = null
let lastFilter = null
const RepairOrderManagement = () => {
  const { ax, msData } = useContext(GlobalStateContext)

  const [dataTable, setDataTable] = useState([])
  const [filterData, setFilterData] = useState({ ...initFilterData })
  const [dialogOpen, setDialogOpen] = useState(false)

  const getData = useCallback((filter) => {
    const postData = {
      ...filter,
      ROStsId: filter.ROStsId === -1 ? null: filter.ROStsId,
      IsNotFin: filter.ROStsId === -1 ? 1 : 0
    }
    ax.post(repairOrderApiNm.getRepairOrder, postData).then(value => {
      if (value.data) {
        lastFilter = postData
        setDataTable(addIdForDataGrid(value.data, "ROId"))
      }
    })
  }, [ax])

  const openDialog = useCallback((id) => {
    selectedId = id
    setDialogOpen(true)
  }, [])

  const onFinish = useCallback(data => {
    if (data) {
      setDataTable(addIdForDataGrid(data, "ROId"))
    } else {
      getData(filterData)
    }
  }, [getData, filterData])

  useEffect(() => {
    getData({ ...initFilterData })
  }, [getData])

  return (
    <BoxFC height="100%" >
      <BoxFR>
        <AddButton onClick={() => openDialog(null)}>เพิ่มการแจ้งซ่อม</AddButton>
        <Box flex={1} />
        <DateTimeSelect
          sx={{ width: 180 }}
          label=""
          start={filterData.ROTmSt}
          end={filterData.ROTmEn}
          setSelectDate={(st, en) => { setFilterData(o => ({ ...o, ROTmSt: st, ROTmEn: en })) }}
          getData={() => { getData(filterData) }}
        />
        <ComboBox sx={{ width: 150 }} options={msData.driverOnlyCombo} label={"พนักงานขับรถ"}
          selectedId={filterData.DrvId}
          setSelectedId={(id) => setFilterData(o => ({ ...o, DrvId: id }))} />

        <ComboBox sx={{ width: 150 }} options={msData.truckCombo} label={"เลขรถ"}
          selectedId={filterData.TukId}
          setSelectedId={(id) => setFilterData(o => ({ ...o, TukId: id }))} />
        <TextField label="สถานะการซ่อม" sx={{ width: 200 }} size="small" select value={filterData.ROStsId}
          onChange={(e) => setFilterData(o => ({ ...o, ROStsId: e.target.value }))}>
          {msData.repairOrderStatuses.map((item) => (
            <MenuItem key={item.ROStsId} value={item.ROStsId}>
              {item.ROStsNm}
            </MenuItem>
          ))}
          <MenuItem key={-1} value={-1}>เปิด+กำลัง ซ่อม</MenuItem>
        </TextField>
        <SearchButton onClick={() => getData(filterData)} />
        <ClearButton onClick={() => setFilterData({ ...clearFilterData })} />
      </BoxFR>

      <Box flex={1}>
        <DataGridCellExpand
          hideFooter
          rows={dataTable}
          columns={columns}
          onRowDoubleClick={(params, event, detail) => openDialog(params.id)}
        />
      </Box>
      <RepairOrderDialog
        selectedId={selectedId}
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        onFinish={onFinish}
        lastFilter={lastFilter} />
    </BoxFC>
  );
}

export default RepairOrderManagement;