import React, { useEffect, useState, useMemo } from "react";
import { Box, Dialog, DialogContent, TextField } from "@mui/material"
import { DialogHeader, DialogFooter } from "components/dialog/DialogHeader";
import ClearButton from "components/buttons/ClearButton";
import PaperComponent from "components/PaperComponent";
import dayjs from "dayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers-pro";

const SelectMonthDialog = ({ oldData, setDialogOpen, dialogOpen, onFinish }) => {
  const [dteData, setDteData] = useState(dayjs().startOf("month"))


  const datePickerProp = useMemo(() => ({
    disableMaskedInput: true,
    openTo: "month",
    views:["year", "month"],
    label: "วันที่คิดค่าเที่ยว",
    inputFormat: "MMMM YYYY",
    value: dteData,
    onChange: (newValue) => { setDteData(newValue) },
    renderInput: (params) => <TextField size="small" {...params}  sx={{ width: 200 }} />,

  }), [dteData])

  useEffect(() => {
    if (dialogOpen) {
      if (oldData) {
        setDteData(oldData)
      } 
    }
  }, [oldData, dialogOpen])

  const handleUpdateColumn = () => {
    // if(dteData){
    //   const date = dayjs(dteData).date()
    //   if(![1,16].includes(date)){
    //     alertError("กรุณาเลือกวันที่ 1 หรือ 16 เท่านั้น")
    //     return
    //   }
    // }
    onFinish(dteData?dayjs(dteData).format("YYYY-MM-01"): null)
    setDialogOpen(false)
  }
  // console.log("in updateDateTime::")
  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth='xs' >
      <DialogHeader  handleDialogClose={() => setDialogOpen(false)}
        title="เลือกเดือนคิดค่าเที่ยว"
        onDeleteClick={null}
      />
      <DialogContent>
        <Box display='flex' flexDirection='column' gap={2} mt={2}>
          <Box display="flex" gap={2} justifyContent="center">
            <DesktopDatePicker {...datePickerProp} />
            <ClearButton onClick={() => setDteData(null)} />
          </Box>
        </Box>
      </DialogContent>
      <DialogFooter okText={"แก้ไขข้อมูล"}
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={handleUpdateColumn} />
    </Dialog>
  )
}

export default React.memo(SelectMonthDialog, (o, n) => o.dialogOpen === n.dialogOpen)