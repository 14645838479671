import { Box, Button, Dialog, DialogContent } from "@mui/material"
import { branchApiNm } from "branch/constant/branchApiNm"
import { BoxFC, BoxFR } from "components/BoxCustom"
import PaperComponent from "components/PaperComponent"
import { DialogFooter, DialogHeader } from "components/dialog/DialogHeader"
import { GlobalStateContext } from "contexts/GlobalStateContext"
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { addIdForDataGrid } from "utils"
import { pcPeriodDriverColumns } from "./columns"
import DataGridCellExpand from "components/DataGridCellExpand/DataGridCellExpand"
import { PrintRounded } from "@mui/icons-material"
import { printPcPeriodDriver } from "./printPCPeriodDriver"
import { addSumRow, getSumColorClassName, sxSumColorClass } from "utils/highlightSumColor"

const PCPeriodDriverDialog = ({ pcPrdId, dialogOpen, setDialogOpen, onFinish }) => {

  const { ax, msData } = useContext(GlobalStateContext)
  const [dataTable, setDataTable] = useState([])

  const modDataTable = useMemo(()=>{
    return addSumRow({
          dataTable: dataTable,
          sumColNms: ["Gate", "Ot", "Toll", "LiftE", "Fee", "EV", "Total"],
          sumColTotal: "DrvNm"
        })
  }, [dataTable])

  const handlePrint = useCallback(() => {
    printPcPeriodDriver({ dataTable: modDataTable, msData })
  }, [msData, modDataTable])


  useEffect(() => {
    if (dialogOpen) {
      ax.post(branchApiNm.getPCPeriodDriver, { PCPrdId: pcPrdId }).then(value => {
        if (value.data) {
          setDataTable(addIdForDataGrid(value.data, "DrvId"))
        }
      })
    } else {
      setDataTable([])
    }
  }, [dialogOpen, ax, pcPrdId])

  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth='xl'
    >
      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title="สรุปรายการบัตรเงินสด"
        onDeleteClick={null}
      />
      <DialogContent>
        <BoxFC pt={2} >
          <BoxFR>
            <Button variant="contained" onClick={handlePrint}><PrintRounded sx={{ mr: 1 }} />พิมพ์</Button>
          </BoxFR>
          <Box height={650} sx={{...sxSumColorClass}}>
            <DataGridCellExpand
              hideFooter
              rows={modDataTable}
              columns={pcPeriodDriverColumns}
              isRowSelectable={(params) => params.id !== 0} 
              experimentalFeatures={{ rowPinning: true }} 
              pinnedRows={{ bottom: [modDataTable.find(row => row.id === 0)] }} 
              getRowClassName={getSumColorClassName}
            />
          </Box>
        </BoxFC>
      </DialogContent>
      <DialogFooter okText={""}
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={null} />
    </Dialog>
  )
}

export default React.memo(PCPeriodDriverDialog)