import { Box, Dialog, DialogContent, Divider, TextField, Typography } from "@mui/material"
import { BoxFC, BoxFR } from "components/BoxCustom"
import PaperComponent from "components/PaperComponent"
import { DialogFooter, DialogHeader } from "components/dialog/DialogHeader"
import { GlobalStateContext } from "contexts/GlobalStateContext"
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { addIdForDataGrid, toNumber } from "utils"
import { bahtProps } from "utils/bahtProps"
import DateTimePickerCustomTime from "components/DateTimePickerCustomTime"
import ComboBox from "components/ComboBox"
import { shoreBalSummary, shoreForBalSelectColumns } from "./columns"
import { pettyCashApiNm, pettyCashColNm, pettyCashPeriodColNm } from "../PettyCash/constant"
import { getSumColorClassName, sxSumColorClass } from "utils/highlightSumColor"
import DataGridCellExpand from "components/DataGridCellExpand/DataGridCellExpand"
import PettyCashDialog from "../PettyCash/PettyCashDialog"
import { UserContext } from "contexts/UserContext"
import { initShoreBalData } from "./initData"
import dayjs from "dayjs"
import { alertConfirmDelete } from "components/Alert"

let selectedPCTrnsId = null
const ShorePeriodDialog = ({ pcPrdId, dialogOpen, setDialogOpen, onFinish, columns }) => {

  const { ax, msData } = useContext(GlobalStateContext)

  const { user } = useContext(UserContext)

  const [dialogData, setDialogData] = useState({ ...initShoreBalData })
  const [selectionModel, setSelectionModel] = useState([])
  const [dataTable, setDataTable] = useState([])
  const [dialogPettyCashOpen, setDialogPettyCashOpen] = useState(false)

  const pcPrdIdObj = useMemo(() => ({ pcPrdId: pcPrdId }), [pcPrdId])

  // const modDataTable = useMemo(() => {
  //   let totalOut = 0
  //   let totalIn = 0
  //   for (const row of dataTable) {
  //     row.AmntOut = row.InOutTyp === "O" ? +row.Amnt : 0
  //     row.AmntIn = row.InOutTyp === "I" ? +row.Amnt : 0
  //     totalOut += row.AmntOut
  //     totalIn += row.AmntIn
  //   }

  //   const result = dataTable.map(item => ({ ...item }))
  //   result.push({
  //     id: 0, Dscp: "รวม", AmntOut: totalOut, AmntIn: totalIn
  //   })
  //   return result
  // }, [dataTable])

  const summaryTable = useMemo(() => {
    const filterdData = dataTable.filter(row => selectionModel.includes(row.id))
    console.log("filterdData", filterdData)
    const result = []
    let totalIn = 0
    let totalOut = 0
    let no = 0
    for (const row of filterdData) {
      totalIn += +row.InAmnt
      totalOut += +row.OutAmnt

      const found = result.find(item => item.ExpId === row.ExpId
        && +item.InAmnt === +row.InAmnt
        && +item.OutAmnt === +row.OutAmnt)

      if (found) {
        found.Qty += 1
      } else {
        no++
        result.push({ ...row, No: no, InAmnt: +row.InAmnt, OutAmnt: +row.OutAmnt, Qty: 1 })
      }
    }

    setDialogData(o => ({ ...o, RecvAmnt: totalIn, ExpAmnt: totalOut, NewBal: +o.PrevBal + totalIn - totalOut }))
    result.push({
      id: 0, ExpNm: "รวม", TotalInAmnt: totalIn, TotalOutAmnt: totalOut
    })
    return result
  }, [selectionModel, dataTable])

  const textFieldProps = useCallback((name, isRequired, isBahtProp) => ({
    fullWidth: true,
    required: isRequired,
    label: pettyCashPeriodColNm[name],
    size: "small",
    error: isRequired && !dialogData[name],
    ...(isBahtProp ? bahtProps : {}),
    value: isBahtProp ? toNumber(dialogData[name]) ? (toNumber(dialogData[name])).toLocaleString() : 0 : dialogData[name] ?? 0,
    onChange: (e) => setDialogData(o => ({ ...o, [name]: isBahtProp ? toNumber(e.target.value) : e.target.value })),
  }), [dialogData, setDialogData])

  const dateTimePickerProp = useCallback((name, isRequired) => ({
    label: pettyCashPeriodColNm[name],
    inputFormat: "DD/MM/YYYY HH:mm",
    mask: "__/__/____ __:__",
    value: dialogData[name],
    onChange: (newValue) => { setDialogData(o => ({ ...o, [name]: newValue })) },
    renderInput: (params) => <TextField size="small" {...params} fullWidth required={isRequired} error={isRequired && !dialogData[name]} />,
  }), [dialogData, setDialogData])


  const setAllData = useCallback((data) => {

    setDialogData(data)
    if (data.PCTrns) {
      setSelectionModel(data.PCTrns.map(row => row.PCTrnsId))

      if (data.PCTrns.length > 0) {
        const postData = {
          PCJnlId: data.PCTrns[0].PCJnlId,
          PayTmSt: dayjs(data.PCTrns[0].PayTm).format("YYYY-MM-DD HH:mm:ss"),
        }
        ax.post(pettyCashApiNm.getPCBalance, postData).then(value => {
          setDialogData(o => ({
            ...o,
            PrevBal: value.data[0].TrnsAmnt || 0,
            NewBal: +(value.data[0].TrnsAmnt || 0) + +(o.RecvAmnt || 0) - +(o.ExpAmnt || 0)
          }))
        })
      }
    }
    if (data.PCTrns.length > 0) {
      setDataTable(addIdForDataGrid(data.PCTrns, "PCTrnsId"))
    } else {
      ax.post(pettyCashApiNm.getPCTransactionNoPrd, { PCJnlId: data.PCJnlId }).then(trnsValue => {
        setDataTable(addIdForDataGrid(trnsValue.data, "PCTrnsId"))
      })
    }
  }, [ax])

  const getData = useCallback(() => {
    ax.post(pettyCashApiNm.getPettyCashPeriod, { PCPrdId: pcPrdId, withDetail: true }).then(value => {
      if (value.data && value.data.length > 0) {
        setAllData(value.data[0])
      }
    })
  }, [ax, pcPrdId, setAllData])

  const handlePCJnlIdChange = useCallback((id) => {
    console.log("handlePCJnlIdchange id::", id)
    setDialogData(o => ({ ...o, PCJnlId: id }))
    if (id) {
      ax.post(pettyCashApiNm.getPCTransactionNoPrd, { PCJnlId: id }).then(value => {
        if (value.data) {
          setDataTable(addIdForDataGrid(value.data, "PCTrnsId"))
        }
      })
      ax.post(pettyCashApiNm.getPCBalance, { PCJnlId: id }).then(value => {
        if (value.data && value.data.length > 0) {
          setDialogData(o => ({ ...o, PrevBal: value.data[0].TrnsAmnt || 0 }))
        }
      })
    } else {
      setDataTable([])
    }
  }, [ax])

  const handleCellClick = useCallback((params) => {
    if (params.field === "ShowDetail") {
      selectedPCTrnsId = params.row.PrPCTrnsId || params.row.PCTrnsId
      setDialogPettyCashOpen(true)
    }
  }, [])


  const onDialogPettCashFinish = useCallback((data) => {
    console.log("onDialogPettyChasFinish data::", data)
    if (data && data.length > 0) {
      setAllData(data[0])
    }
    else {
      getData()
    }
  }, [setAllData, getData])

  const handleSelectionModelChange = useCallback((newSelection) => {
    setSelectionModel(newSelection)

  }, [])


  const handleDeleteClick = useCallback(() => {
    alertConfirmDelete(() => {
      ax.post(pettyCashApiNm.deletePettyCashPeriod, { PCPrdId: pcPrdId, getArgs: null }).then(value => {
        if (value.data) {
          setDialogOpen(false)
          onFinish()
        }
      })
    })
  }, [ax, pcPrdId, onFinish, setDialogOpen])

  const handleDialogOk = useCallback(() => {
    if (dialogData.PCPrdId) {
      const postData = {
        PCPrdId: dialogData.PCPrdId,
        ColValues: { Rmk: dialogData.Rmk },
        LogRmk: "แก้ไข หมายเหตุ"
      }
      ax.post(pettyCashApiNm.updatePCProidCols, postData).then(value => {
        if (value.data) {
          setDialogOpen(false)
          onFinish()
        }
      })
    } else {
      const postData = {
        ...dialogData,
        PCTrnsIds: selectionModel,
        getArgs: null
      }
      const apiNm = pettyCashApiNm.insertPettyCashPeriodWithItems
      ax.post(apiNm, postData).then(value => {
        if (value.data) {
          setDialogOpen(false)
          onFinish()
        }
      })
    }
  }, [ax, dialogData, selectionModel, setDialogOpen, onFinish])

  useEffect(() => {
    if (dialogOpen) {
      console.log("pcPrdId::", pcPrdId)
      if (pcPrdId) {
        getData()
      } else {
        setDialogData({ ...initShoreBalData, PrdAdmNNm: user.NName, AdmAccId: user.AccId })
      }
    } else {
      setDialogData({ ...initShoreBalData })
      setDataTable([])
      setSelectionModel([])
      selectedPCTrnsId = null
    }
  }, [user, dialogOpen, getData, pcPrdId])

  console.log("------- ShorePeriodDialog ---------- dialogData", dialogData)
  console.log("------- ShorePeriodDialog ---------- pcPrdId", pcPrdId)
  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth 
      PaperProps={{
        style: {
          maxWidth: 1700,
          minWidth: 1700,
          width: 1700
        }
      }}
    >
      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title="ปิดยอดการรับ-จ่ายเงิน"
        onDeleteClick={pcPrdId ? handleDeleteClick : null}
      />
      <DialogContent>
        <BoxFR height={750} pt={2} sx={{ alignItems: "stretch" }}>
          <BoxFC width={250} sx={{ gap: 1 }}>
            <Typography variant="h6">รายละเอียด</Typography>
            <DateTimePickerCustomTime {...dateTimePickerProp("PCPrdTm", true)} disabled={dialogData.PCPrdId? true: false}/>
            <ComboBox sx={{ width: "100%" }} options={msData.pcJournalCombo} required={true}
              label={pettyCashColNm.PCJnlId}
              selectedId={dialogData.PCJnlId}
              setSelectedId={handlePCJnlIdChange}
              disabled={(dialogData?.PCTrns?.length > 0) ? true : false}
            />

            <TextField {...textFieldProps("PrdAdmNNm", true)} disabled label="ผุ้ทำรายการ" />
            <TextField {...textFieldProps("PrevBal", false, true)} disabled label="ยอดเงินคงค้าง" onChange={null} />
            <TextField {...textFieldProps("RecvAmnt", false, true)} disabled label="รวมเงินรับ" onChange={null} />
            <TextField {...textFieldProps("ExpAmnt", false, true)} disabled label="รวมค่าใช้จ่าย" onChange={null} />
            <TextField {...textFieldProps("NewBal", false, true)} disabled label="ยอดเงินคงเหลือ" onChange={null} />
            <TextField {...textFieldProps("Rmk", false)} multiline rows={3} />
          </BoxFC>
          <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
          <BoxFC sx={{ gap: 1, flex: 1 }}>
            <BoxFR>
              <Typography variant="h6">{dialogData.PCTrns?.length > 0 ? "รายการปิดยอด" : "รายการที่ยังไม่ปิดยอด"}</Typography>

            </BoxFR>
            <Box height={350} mt={-1} width={"100%"} >
              <DataGridCellExpand
                checkboxSelection={dialogData.PCTrns?.length > 0 ? false : true}
                selectionModel={selectionModel}
                onSelectionModelChange={handleSelectionModelChange}
                hideFooter
                rows={dataTable}
                columns={columns || shoreForBalSelectColumns}
                // getRowClassName={getSumColorClassName}
                // pinnedRows={{ bottom: [modDataTable.find(row => row.id === 0)] }}
                onCellClick={handleCellClick}
              />
            </Box>
            <Divider />
            <Typography variant="h6">รายการสรุปค่าใช้จ่าย</Typography>
            <Box flex={1} mt={-1} width="100%" sx={sxSumColorClass}>
              <DataGridCellExpand
                experimentalFeatures={{ rowPinning: true }}
                hideFooter
                rows={summaryTable}
                columns={shoreBalSummary}
                getRowClassName={getSumColorClassName}
                pinnedRows={{ bottom: [summaryTable.find(row => row.id === 0)] }} />
            </Box>
          </BoxFC>
        </BoxFR>
        <PettyCashDialog
          selectedId={selectedPCTrnsId}
          dialogOpen={dialogPettyCashOpen}
          setDialogOpen={setDialogPettyCashOpen}
          onFinish={onDialogPettCashFinish}
          lastFilter={pcPrdIdObj}
        />
      </DialogContent>
      <DialogFooter okText="บันทึก"
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={handleDialogOk} />
    </Dialog>
  )
}

export default React.memo(ShorePeriodDialog)