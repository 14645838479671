const FOLDER_DRIVER_IMAGE = "image/driver/";
const FOLDER_SHIPMENT_FILE = "file/shipment/";
const FOLDER_INVOICE_FILE = "file/invoice/";
const FOLDER_PAYMENT_VOUCHER = "file/payment-voucher/";
const FOLDER_RECEIVE_VOUCHER_FILE = "file/receive-voucher/";
const FOLDER_CUSTOMER_FILE = "file/customer/";
const FOLDER_JOB_FILE = "file/job/";
const FILDER_TRUCK_FILE = "file/truck/";
const PLAN_COLORS = [
  "#FFFFFF", "#339933", "#66FF99", "#FFFF99", "#FF9933", "#FF0000", "#FF66FF"
  , "#6699FF","#003399", "#996633", "#000000"
]
const SHIPMENT_TYPE = {
  EXPORT: 1,
  IMPORT: 2,
  CARRY: 3
}

export const coreApiNm = {
  checkSession: "/no-session/checkSession",
  saveServerData: "/core/saveServerData",
  insertMemo: "/core/insertMemo",
  updateMemo: "/core/updateMemo",
  getMasterData: "/core/getMasterData",
  getDashBoardOperation: "/core/getDashBoardOperation",
  getDashBoardDailyData: "/core/getDashBoardDailyData",
  getTargetOperation: "/core/getTargetOperation",
  getCustomerSummary: "/core/getCustomerSummary",
  getMonthlyJobReportClient: "/core/getMonthlyJobReportClient",
  getMonthlyIncomeReportClient: "/core/getMonthlyIncomeReportClient",
  getMonthlyJobReport: "/core/getMonthlyJobReport",
  getMonthlyDataReportClient: "/core/getMonthlyDataReportClient",
  getVanningData: "/core/getVanningData",
  getLateData: "/core/getLateData",
  getMonthlyIncomeReport: "/core/getMonthlyIncomeReport",
  getSubcontractReport: "/core/getSubcontractReport",
  getInvoiceNotPayReport: "/core/getInvoiceNotPayReport",
  getMasterDataFinance: "/core/getMasterDataFinance",
  getDriverStatus: "/core/getDriverStatus",
  getDriverStatusStsTm: "/core/getDriverStatusStsTm",
  getPunctualReportSummary: "/core/getPunctualReportSummary",
  getPunctualReport: "/core/getPunctualReport",
  getDriverJobReport: "/core/getDriverJobReport",
  getLogData: "/core/getLogData",
  getLogFilterValue: "/core/getLogFilterValue",
  getCompanyAddress: "/core/getCompanyAddress",
  getTruckLocation: "/core/getTruckLocation",
  updateContainerStatus: "/core/updateContainerStatus"
}

export const G_QUERY_LIMIT  = 10000

export {FOLDER_DRIVER_IMAGE, FOLDER_SHIPMENT_FILE, PLAN_COLORS
  , SHIPMENT_TYPE, FOLDER_INVOICE_FILE, FOLDER_PAYMENT_VOUCHER
  , FOLDER_CUSTOMER_FILE, FOLDER_JOB_FILE, FOLDER_RECEIVE_VOUCHER_FILE
  , FILDER_TRUCK_FILE}