import { Box, Button, CircularProgress, Dialog, DialogContent, Divider, IconButton, Paper, Typography } from "@mui/material";
import { initPageData, jobOrderApiNm } from 'constants/jobOrderConstant';
import { blueGrey, red, yellow } from "@mui/material/colors";
import { BoxFC, BoxFR } from "components/BoxCustom";
import PaperComponent from "components/PaperComponent";
import { DialogFooter, DialogHeader } from "components/dialog/DialogHeader";
import { GlobalStateContext } from "contexts/GlobalStateContext";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import ContainerStatus from "./ContainerStatus";
import ContStatus from "./DailyJobDialogV2Component/ContStatus";
// import Expense from "./DailyJobDialogV2Component/Expense";
import JobOrderDriver from "./DailyJobDialogV2Component/JobOrderDriver";
import JobOrderData from "./DailyJobDialogV2Component/JobOrderData";
import Shipment from "./DailyJobDialogV2Component/Shipment";
import Shore from "./DailyJobDialogV2Component/Shore";
import Memo from "./DailyJobDialogV2Component/Memo";
import dayjs from "dayjs";
import { printJobOrder } from "branch/functions/printJobOrder";
import { UserContext } from "contexts/UserContext";
import ButtonGroupPopper from "components/ButtonGroupPopper";
import { alertConfirmDelete, alertError } from "components/Alert";
import { checkContStatus } from "./DailyJobDialogV2Component/checkContStatus";
import PettyCash from "./DailyJobDialogV2Component/PettyCash";
import ComboBox from "components/ComboBox";
import { Cancel, PersonPin } from "@mui/icons-material";
import { addIdForDataGrid } from "utils";
import { pettyCashApiNm } from "../PettyCash/constant";
import { suggessDrvCombo } from "utils/suggessDrvCombo";
// import PettyCash from "./DailyJobDialogV2Component/PettyCash";

let prevContStsData = []
let oContStatusData = []
let oPettyCashData = { advData: [], itmData: [] }
let oJOData = {}
let oTagData = []
let oMemoData = []
// let oMemoData = []


const DailyJobDialogV3 = ({ selectedId, dialogOpen, setDialogOpen, lastFilter, onFinish }) => {

  const { ax, msData, setTargetTimestamp } = useContext(GlobalStateContext)
  const { user } = useContext(UserContext)

  const [contStatusData, setContStsData] = useState([])
  const [joData, setJOData] = useState({ ...initPageData })
  const [tagData, setTagData] = useState([])
  const [pettyCashData, setPettyCashData] = useState({ advData: [], itmData: [] })
  const [memoData, setMemoData] = useState([])
  const [loadProcessDrvId, setLoadProcessDrvId] = useState(false)
  // const [loaded, setLoaded] = useState(false)

  const suggestProcessDrvCombo = useMemo(() => {
    let driverCombo = msData.driverCombo.map(item => ({ ...item }))
    const contStsDrvIds = new Set()
    for (const contSts of contStatusData) {
      contSts.DrvId && contStsDrvIds.add(contSts.DrvId)
    }
    const contStsDrvIdsArr = Array.from(contStsDrvIds)
    for (const drvId of contStsDrvIdsArr) {
      driverCombo = suggessDrvCombo(drvId, driverCombo)
    }
    return driverCombo
  }, [msData.driverCombo, contStatusData])
  const isPayAdv = useMemo(() => {
    return pettyCashData.advData.some(item => item.UsrAccId && item.isFromProcessDrvId)
  }, [pettyCashData.advData])

  const curAdvAmnt = useMemo(() => {
    // console.log("pettyCashData.advData::", pettyCashData.advData)
    return pettyCashData.advData.reduce((prev, cur) =>
      joData.processDrvId ?
        prev + (cur.UsrAccId === joData.processDrvId ? ((+cur.Amnt) * (cur.InOutTyp === "I" ? -1 : 1)) - +(cur.ChdAmnt || 0) - +(cur.ItmAmnt || 0) : 0)
        :
        prev + ((+cur.Amnt) * (cur.InOutTyp === "I" ? -1 : 1)) - +(cur.ChdAmnt || 0) - +(cur.ItmAmnt || 0)
      , 0)

  }, [pettyCashData.advData, joData.processDrvId])
  const deleteJobOrder = useCallback((jobOrdId) => {
    // console.log("in deleteJobOrder lastFilter::", lastFilter)
    alertConfirmDelete(() => {
      ax.post(jobOrderApiNm.deleteJobOrder, { JobOrdId: jobOrdId }).then(value => {
        if (value.data) {
          // processConstStatus(value.data, msData)
          //setDataTable(addIdForDataGrid(value.data, "JobOrdId"));
          setDialogOpen(false);
          setTargetTimestamp(dayjs())
          onFinish()
        }
      })
    })
  }, [ax, setTargetTimestamp, onFinish, setDialogOpen])

  const handleDialogOK = useCallback(async () => {
    //set joData back to init data

    if (!joData.AptTm) {
      alertError("กรุณาตรวจสอบข้อมูล")
      return
    }
    joData.processDrvId = null

    const pcHasChange = JSON.stringify(oPettyCashData) !== JSON.stringify(pettyCashData)
    const contStsHasChange = JSON.stringify(oContStatusData) !== JSON.stringify(contStatusData)
    const tagDataHasChange = JSON.stringify(oTagData) !== JSON.stringify(tagData)
    const joDataHasChange = JSON.stringify(oJOData) !== JSON.stringify(joData)
    const memoDataHasChange = JSON.stringify(oMemoData) !== JSON.stringify(memoData)
    console.log("handleDialogOK::", joDataHasChange, contStsHasChange, pcHasChange)

    const postData = {
      getArgs: lastFilter
    }

    if (pcHasChange) {
      const findChangedAdvData = (advData) => {
        const results = []
        const advKeys = ["UsrAccId", "PayTm", "ExpId", "Amnt", "Dscp", "Rmk", "PCJnlId"]
        for (const data of oPettyCashData.advData) {
          const found = advData.find(adv => adv.PCTrnsId === data.PCTrnsId)
          for (const key of advKeys) {
            if (found && found[key] !== data[key]) {
              results.push(found)
              break
            }
          }
        }
        return results
      }
      //   console.log("pettyCashData has changed pettyCashData::", pettyCashData)
      //   //find changed advData from oPettyCashData.advData
      //   console.log("1111111111111::", ...pettyCashData.itmData
      //     .filter(itm => !itm.PCItmId && itm.postData?.transactionArgs)
      //     .map(item => item.postData.transactionArgs)
      //     .filter(adv => !adv.PCTrnsId && !adv.isReimbursement))
      //   console.log("222222222222222", ...findChangedAdvData(pettyCashData.itmData
      //     .filter(itm => !itm.PCItmId && itm.postData?.transactionArgs)
      //     .map(item => item.postData.transactionArgs)))
      // return
      const advData = [
        ...pettyCashData.advData.filter(adv => !adv.PCTrnsId),
        ...findChangedAdvData(pettyCashData.advData),
        ...pettyCashData.itmData
          .filter(itm => !itm.PCItmId && itm.postData?.transactionArgs)
          .map(item => item.postData.transactionArgs)
          .filter(adv => !adv.PCTrnsId && !adv.isReimbursement),
        ...findChangedAdvData(pettyCashData.itmData
          .filter(itm => !itm.PCItmId && itm.postData?.transactionArgs)
          .map(item => item.postData.transactionArgs))
      ]
      // const advData = [...postData.advData, ...]
      const itmData = pettyCashData.itmData
        .filter(itm => !itm.PCItmId)
        .map(item => item.isReimbursement ?
          {
            ...item.postData.itemArgs,
            transactionArgs: item.postData.transactionArgs
          } : item.postData.itemArgs)

      // console.log("advData::", advData)
      // console.log("itmData::", itmData)
      postData.pettyCashData = { advData, itmData }
    }

    if (contStsHasChange) {
      postData.contStatusData = contStatusData
    }

    if (tagDataHasChange) {
      // await ax.post(jobOrderApiNm.updateJobOrderTag, { JobOrdIds: [selectedId], TagIds: tagData.map(tag => tag.id) })
      postData.tagData = { JobOrdIds: [selectedId], TagIds: tagData.map(tag => tag.id) }
    }

    const joColValue = {}
    if (joDataHasChange) {
      for (const key in joData) {
        if (oJOData[key] !== joData[key]) {
          joColValue[key] = joData[key]
        }
      }
      postData.jobOrdData = {
        JobOrdId: joData.JobOrdId,
        ColValues: joColValue,
        LogRmk: "แก้ไขข้อมูลใบงาน"
      }
    }
    if (memoDataHasChange) {
      const memoText = memoData && memoData.length > 0 ?
        JSON.stringify(memoData.map(memo => ({
          IsPin: memo.IsPin,
          Memo: memo.Memo,
          MemTm: dayjs(memo.MemTm).format("YYYY-MM-DD HH:mm:ss"),
          ModAccId: memo.ModAccId,
        })))
        : null
      if (postData.jobOrdData) {

        postData.jobOrdData.ColValue = {
          ...postData.jobOrdData.ColValue,
          Memo: memoText
        }
      } else {
        postData.jobOrdData = {
          JobOrdId: joData.JobOrdId,
          ColValues: { Memo: memoText },
          LogRmk: "แก้ไข Memo"
        }
      }
    }
    // console.log(oJOData, joData)
    if (pcHasChange || contStsHasChange || tagDataHasChange || joDataHasChange || memoDataHasChange) {
      // console.log("postData::", postData)
      ax.post(jobOrderApiNm.updateJobOrderAllDetailV3, postData).then(value => {
        if (value.data) {
          onFinish && onFinish(value.data)
        }
      })
    }
    setDialogOpen(false)

  }, [joData, pettyCashData, contStatusData, tagData, ax, lastFilter, memoData, onFinish, selectedId, setDialogOpen])

  const handlePrintJobOrder = useCallback(() => {
    console.log("joData::", joData)
    printJobOrder({
      printDataArr: [{
        jobOrderData: joData, shipmentData: joData.Shipment, jobOrdIds: [joData.JobOrdId],
        fName: user.FName
      }], msData
    })
  }, [joData, msData, user.FName])

  const handlePrintJobOrderWithBg = useCallback(() => {
    console.log("joData::", joData)
    printJobOrder({
      printDataArr: [{
        jobOrderData: joData, shipmentData: joData.Shipment, jobOrdIds: [joData.JobOrdId],
        fName: user.FName
      }],
      msData,
      withBackground: true
    })
  }, [joData, msData, user.FName])

  const handleRemoveIsPayAdv = useCallback(() => {
    setPettyCashData(o => {
      console.log("o.advData::", o.advData)
      return {
        advData: o.advData.map(item => ({
          ...item,
          AdmAccId: user.AccId,
          UsrAccId: item.isFromProcessDrvId ? null : item.UsrAccId,
          UsrNNm: item.isFromProcessDrvId ? "" : item.UsrNNm,
          isFromProcessDrvId: false
        })),
        itmData: o.itmData
      }
    })
  }, [user.AccId])

  useEffect(() => {
    checkContStatus({ contStatusData, joData, prevContStsData })
  }, [contStatusData, joData])

  //useEffect for joData.processDrvId change
  useEffect(() => {
    console.log("joData.processDrvId change ::", joData.processDrvId)
    setPettyCashData(o => {
      // //find first if not found return original
      // const found = o.advData.find(item => !item.UsrAccId || item.isFromProcessDrvId)
      // if (found) {
      //   const newAdvData = o.advData.map(item => ({ ...item }))
      //   for (const item of newAdvData) {
      //     if (item.UsrAccId && !item.isFromProcessDrvId) continue
      //     item.AdmAccId = user.AccId
      //     item.UsrAccId = joData.processDrvId
      //     item.UsrNNm = msData.accountAllCombo.find(acc => acc.id === joData.processDrvId)?.label || ""
      //     item.isFromProcessDrvId = joData.processDrvId ? true : false
      //   }
      //   return { advData: newAdvData, itmData: o.itmData }
      // } else {
      //   return o
      // }

      //Only assign UsrAccId if there is only 1 Exp
      const newAdvData = o.advData.map(item => ({ ...item }))
      const filtered = newAdvData.filter(item => !item.UsrAccId || item.isFromProcessDrvId)
      console.log("filtered::", filtered)
      if (filtered.length === 1) {
        //cannot use filtered data coz it is new ref so loop and find
        // const item = filtered[0]
        const found = newAdvData.find(item => item.PCTrnsId === filtered[0].PCTrnsId)
        found.AdmAccId = user.AccId
        found.UsrAccId = joData.processDrvId
        found.UsrNNm = msData.accountAllCombo.find(acc => acc.id === joData.processDrvId)?.label || ""
        found.isFromProcessDrvId = joData.processDrvId ? true : false
        return { advData: newAdvData, itmData: o.itmData }
      } else {
        return o
      }

    })


    if (joData.processDrvId) {
      setLoadProcessDrvId(true)
      ax.post(pettyCashApiNm.getPCTransactionNotCmptByAccId, { UsrAccId: joData.processDrvId }, false).then(value => {
        setLoadProcessDrvId(false)
        if (value.data) {
          setPettyCashData(o => {
            let lastNo = o.advData.length
            const modAdvDta = value.data.map(item => ({
              ...item,
              No: lastNo + 1,
              id: item.PCTrnsId,
              isAdvFromUsrAccId: true,
              AdmAccId: user.AccId,
            }))
            return {
              advData: [...o.advData.filter(item => !item.isAdvFromUsrAccId), ...modAdvDta],
              itmData: o.itmData
            }
          })
        }
      })
    } else {
      setPettyCashData(o => {
        return {
          advData: o.advData.filter(item => !item.isAdvFromUsrAccId),
          itmData: o.itmData
        }
      })
    }
  }, [joData.processDrvId, msData.accountAllCombo, ax, user.AccId])

  useEffect(() => {
    if (dialogOpen) {
      ax.post(jobOrderApiNm.getDailyJobAllDetailV3, { JobOrdId: selectedId }).then(value => {
        if (value.data) {
          const contStses = value.data.ContStses
          oContStatusData = msData.containerStatuses.filter(item => !item.IsHide).map(contStatus => {
            const foundData = contStses?.find(it => it.ContStsId === contStatus.ContStsId)
            return {
              ...contStatus,
              DrvId: foundData && foundData.DrvId,
              TukId: foundData && foundData.TukId,
              JobOrdId: selectedId,
              StsTm: foundData && foundData.StsTm,
              Rmk: foundData && foundData.Rmk,
              IsCmpt: foundData && foundData.IsCmpt
            }
          })
          delete value.data.ContStses

          oPettyCashData = {
            advData: addIdForDataGrid(value.data.PettyCash.advData, "PCTrnsId"),
            itmData: addIdForDataGrid(value.data.PettyCash.itmData, "PCItmId")
          }

          oTagData = value.data.Tags.map(item => ({ id: item.TagId, label: item.Name }))

          !value.data.Memo && (value.data.Memo = [])
          oMemoData = value.data.Memo.map((item, index) => ({ ...item, id: index }))
          delete value.data.Memo
          oJOData = { ...value.data }
          oJOData.processDrvId = null

          oJOData.Shipment = {
            CusNm: oJOData.CusNm,
            JobNo: oJOData.JobNo,
            Bkg: oJOData.Bkg,
            Agnt: oJOData.Agnt,
            Port: oJOData.Port,
            Vssl: oJOData.Vssl,
            Voy: oJOData.Voy,
            ShpmTypId: oJOData.ShpmTypId,
            Job: {
              RcptNm: oJOData.RcptNm,
              RcptAddr: oJOData.RcptAddr,
              RcptTaxId: oJOData.RcptTaxId,
              RcptBrchNo: oJOData.RcptBrchNo,
              Name: oJOData.JobNm,
              CmdyTyp: oJOData.CmdyTyp
            }
          }
          oJOData.ShRtnPOIId = oJOData.Shore?.RtnPOIId
          oJOData.ShRtnPlc = oJOData.Shore?.RtnPlc || ""
          oJOData.ShVssl = oJOData.Shore?.Vssl || ""
          oJOData.ShVoy = oJOData.Shore?.Voy || ""
          setContStsData(oContStatusData.map(item => ({ ...item })))
          prevContStsData = oContStatusData.map(item => ({ ...item }))
          setPettyCashData({ advData: oPettyCashData.advData.map(item => ({ ...item })), itmData: oPettyCashData.itmData.map(item => ({ ...item })) })
          setJOData({ ...oJOData })
          setMemoData(oMemoData.map((item) => ({ ...item })))
          setTagData(oTagData.map(item => ({ ...item })))

        }
      })
    } else {
      setTagData([])
      setJOData({ ...initPageData })
      setContStsData([])
      setPettyCashData({ advData: [], itmData: [] })
      setMemoData([])
      prevContStsData = []
      oContStatusData = []
      oPettyCashData = { advData: [], itmData: [] }
    }
  }, [dialogOpen, selectedId, ax, msData.containerStatuses])
  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth
      PaperProps={{
        style: {
          minWidth: 1850,
          maxWidth: 1850,
          minHeight: 930,
          maxHeight: 930,
          height: 930
        }
      }} >
      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title={`ข้อมูลใบงานเลขที่ ${selectedId}`}
        onDeleteClick={() => deleteJobOrder(selectedId)}>
        <Box display="flex" gap={2} ml={2}>
          {/* <Box display="flex" alignItems="center">
              <Typography variant="h6" color="#000000"> แก้ไขใบงานเลขที่ {selectedId} </Typography>
            </Box> */}
          {!joData.PrId &&
            <Button variant="contained" color="secondary" onClick={() => window.open(`${window.location.origin}/emily/AddJobOrderChild/${selectedId}`, "_blank")}>เพิ่ม/พิมพ์ใบงานย่อย</Button>
          }
          <ButtonGroupPopper mainButton={
            <Button variant="contained" color="secondary"
              onClick={handlePrintJobOrder} >
              พิมพ์ซ้ำใบงาน
            </Button>
          }
            arrowButtonStyle={{ variant: "contained", color: "secondary" }}>
            <Paper >
              <Button variant="outlined" size="small" color="secondary" onClick={handlePrintJobOrderWithBg}>พิมพ์พร้อมพื้นหลัง</Button>
            </Paper>
          </ButtonGroupPopper>
        </Box>
      </DialogHeader>
      <DialogContent sx={{ bgcolor: blueGrey[50], p: 1, height: "100%" }}>
        <BoxFR height="100%" pt={1} sx={{ alignItems: "stretch" }}>
          <BoxFC sx={{ gap: 1, height: "100%", width: 520 }}>
            <Paper sx={{ pt: 1, pb: 1, width: "100%", height: 130 }}>
              <BoxFR ml={2}>
                <Typography variant="h6">สถานะตู้</Typography>
              </BoxFR>
              <ContStatus contStatusData={contStatusData} joData={joData} />
            </Paper>

            <Paper sx={{ px: 2, pt: 1, pb: 2, width: "100%" }}>
              <BoxFC sx={{ gap: 1 }}>
                <Typography variant="h6" sx={{ width: "100%" }}>ข้อมูลใบแจ้งลาก</Typography>
                <Shipment joData={joData} msData={msData} />
              </BoxFC>
            </Paper>
            <Paper sx={{ px: 2, pt: 1, pb: 2, width: "100%", }}>
              <BoxFC sx={{ gap: 1 }}>
                <Typography variant="h6" sx={{ width: "100%" }}>ข้อมูลชอร์</Typography>
                <Shore joData={joData} msData={msData} />
              </BoxFC>
            </Paper>
            <Paper sx={{ px: 2, pt: 1, pb: 2, width: "100%", flex: 1 }}>
              <BoxFC sx={{ gap: 1 }} height="100%">
                <Memo memoData={memoData} setMemoData={setMemoData} dialogOpen={dialogOpen} msData={msData} />
              </BoxFC>
            </Paper>
          </BoxFC>
          <Paper sx={{ px: 2, width: 600, pt: 1, height: "100%" }}>
            <JobOrderData joData={joData} setJOData={setJOData} msData={msData} tagData={tagData} setTagData={setTagData} />
          </Paper>

          <Paper sx={{ px: 2, pt: 1, pb: 2, alignSelf: "stretch", height: "100%", flex: 1 }}>
            <BoxFC height="100%" width="100%" sx={{ gap: 0 }}>
              <BoxFR sx={{ alignItems: "flex-start", width: "100%" }} >
                <BoxFC flex={1} sx={{ gap: 1 }}>
                  <BoxFC sx={{ gap: 1 }}>
                    <BoxFR>
                      <Typography variant="h6" >ผู้รับ-ส่งใบงาน</Typography>
                      <PersonPin color="primary" />
                    </BoxFR>
                    <BoxFR>
                      <ComboBox sx={{ flex: 1 }} options={suggestProcessDrvCombo} label={"พนักงานขับรถ"}
                        selectedId={joData.processDrvId}
                        setSelectedId={(id) => setJOData(o => ({ ...o, processDrvId: id }))}
                      />
                      <Box flex={1}>
                        {isPayAdv ?
                          <BoxFR p={1} borderRadius={1} bgcolor={yellow[200]} justifyContent="space-between">
                            <Typography  >!จ่ายเงินล่วงหน้า</Typography>
                            <IconButton sx={{ p: 0 }} onClick={handleRemoveIsPayAdv}>
                              <Cancel />
                            </IconButton>
                          </BoxFR>
                          : null
                        }
                      </Box>
                    </BoxFR>
                    <BoxFR height={30}>
                      <Typography sx={{ flex: 1 }} fontWeight="bold">เงินล่วงหน้าคงค้าง: </Typography>
                      <BoxFR sx={{ flex: 1, justifyContent: "flex-end" }}>
                        {loadProcessDrvId ? <CircularProgress size={20} />
                          :
                          <Typography color={curAdvAmnt === 0 ? "black" : red[800]}>{(curAdvAmnt).toLocaleString()}</Typography>
                        }
                        <Typography>บาท</Typography>
                      </BoxFR>
                    </BoxFR>
                  </BoxFC>
                  <Divider width="80%" sx={{ alignSelf: "center" }} />
                  <Typography variant="h6" >ผู้รับผิดชอบปัจจุบัน</Typography>
                  <JobOrderDriver joData={joData} setJOData={setJOData} msData={msData} suggessDrvCombo={suggestProcessDrvCombo}/>
                </BoxFC>
                <BoxFC sx={{ flex: 1, gap: 0 }}>
                  <Typography variant="h6" >พนักงานขับรถ</Typography>
                  <ContainerStatus
                    msData={msData}
                    options={msData.driverCombo}
                    contStsData={contStatusData}
                    setContStsData={setContStsData}
                    setJobOrderData={setJOData}
                    processDrvId={joData.processDrvId} />
                </BoxFC>
              </BoxFR>
              <BoxFC sx={{ gap: 1, flex: 1, mt: -2, position: "relative" }}>
                <PettyCash
                  pettyCashData={pettyCashData}
                  setPettyCashData={setPettyCashData}
                  contStsData={contStatusData}
                  joData={joData} />
                {/* <Box width={125} bgcolor={grey[500]}
                  sx={{ height: "24px", position: "absolute", left: 0, top: 0, opacity: 0.5 }} />
                <Box width="100%" bgcolor={grey[500]}
                  sx={{ height: "calc(100% - 24px)", mt: "24px", position: "absolute", left: 0, top: 0, opacity: 0.5 }} /> */}
              </BoxFC>
            </BoxFC>
          </Paper>
        </BoxFR>
      </DialogContent>
      <DialogFooter okText="แก้ไขข้อมูล"
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={handleDialogOK} />

      {/* <Paper sx={{ pl: 2, pr: 0, py: 1, width: "100%" }}>
              
<BoxFC sx={{ height: 140, overflow: "auto", gap: 1 }}>
  <Typography variant="h6">เงินแนบใบงาน</Typography>
  <Expense expenseData={expenseData} setExpenseData={setExpenseData} ax={ax} msData={msData} user={user} />
</BoxFC>
</Paper> */}
    </Dialog>

  );
}

export default DailyJobDialogV3;
