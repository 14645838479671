import React, { useCallback, useMemo, useState, useContext } from "react";
import { Box, Button, Dialog, DialogContent, Paper } from "@mui/material"
import ModAccName from "components/ModAccName";
import { DialogFooter, DialogHeader } from "components/dialog/DialogHeader";
import { initDialogData, shipmentApiNm } from "constants/shipmentConstant";
import { getISOCd, sortObj, validate } from "utils";
import { GlobalStateContext } from "contexts/GlobalStateContext";
import FileUpload from "components/FileUplaod/FileUpload";
import { coreApiNm, FOLDER_SHIPMENT_FILE } from "constants/serverConstant";
import dayjs from "dayjs";
import { initFileUploadData } from "constants/fileUploadConstant";
import ShipmentData from "components/ShipmentData";
// import MemoComponent from "components/MemoComponent";
import { useEffect } from "react";
import { BoxFC, BoxFR } from "components/BoxCustom";
import PaperComponent from "components/PaperComponent";
import CheckboxFormControl from "components/CheckboxFormControl";
import { alertConfirmDelete, alertError, alertWarning } from "components/Alert";
import { saveFile } from "components/FileUplaod/saveFile";
import { blueGrey } from "@mui/material/colors";
import { ContentCopyRounded, DocumentScannerRounded, PrintRounded } from "@mui/icons-material";
import PrintJobOrderDialog from "./PrintJobOrderDialog";
import { printShipment } from "branch/functions/printShipment";
import { getDataAndPrintJobOrderByShpmId } from "utils/getDataAndPrintjobOrder";
import { UserContext } from "contexts/UserContext";
import ReadFileDialog from "./ReadFileDialog";

let firstData = null;
const ShipmentDialog = ({ state, fn }) => {
  const { user } = useContext(UserContext)

  const { dialogOpen, selectedId, lastFilter, oneYearVsslData = [], oneYearPortData = [], isInsertReturnShpmId } = state
  const { setDialogOpen, onFinish } = fn
  const [dialogReadFileOpen, setDilaogReadFileOpen] = useState(false)
  const [dialogData, setDialogData] = useState(initDialogData)
  const { ax, msData } = useContext(GlobalStateContext);
  const [fileUploadData, setFileUploadData] = useState({ ...initFileUploadData() })
  const [isShowShipmentData, setIsShowShipmentData] = useState(true)
  const [memoData, setMemoData] = useState([])
  const [isClearData, setIsClearData] = useState(true)
  const [mShpmId, setmShpmId] = useState(0)
  const [dialogPrintJobOrderOpen, setDialogPrintJobOrderOpen] = useState(false)




  initDialogData.RecvDocDte = dayjs();

  const validation = useMemo(() => ({
    RecvDocDte: dialogData.RecvDocDte == null || !dayjs(dialogData.RecvDocDte).isValid(),
    JobNo: dialogData.JobNo === "",
    ShpmTypId: dialogData.ShpmTypId === "" || dialogData.ShpmTypId === null,
    JobId: dialogData.JobId === null,
    TakeDteSt: dialogData.TakeDteSt != null && !dayjs(dialogData.TakeDteSt).isValid(),
    TakeDteEn: dialogData.TakeDteEn != null && !dayjs(dialogData.TakeDteEn).isValid(),
    RtnDteSt: dialogData.RtnDteSt != null && !dayjs(dialogData.RtnDteSt).isValid(),
    RtnDteEn: dialogData.RtnDteEn != null && !dayjs(dialogData.RtnDteEn).isValid(),
  }), [dialogData])

  // const getMemoArgs = useMemo(() => ({
  //   ShpmId: dialogData.ShpmId
  // }), [dialogData.ShpmId])

  const deleteShipment = useCallback((shpmId) => {
    alertConfirmDelete(() => {
      ax.post(shipmentApiNm.deleteShipment, { ShpmId: shpmId, getArgs: lastFilter }).then(value => {
        if (value.data) {
          if (onFinish) {
            onFinish(value.data);
          }

          setDialogOpen(false);
        }
      })
    })
  }, [ax, lastFilter, onFinish, setDialogOpen])

  const insertUpdateShipment = useCallback(async (initShpmId, dialogData, fileUploadData, setFileUploadData, memData
    , isClearData, withPrintJobOrder) => {
    const isInsert = initShpmId === 0;
    for (const file of fileUploadData.serverFiles) {
      if (file.isEdit) {
        alertWarning("กรุณายืนยันชื่อไฟล์ก่อน");
        return;
      }
    }
    for (const file of fileUploadData.addedFiles) {
      if (file.isEdit) {
        alertWarning("กรุณายืนยันชื่อไฟล์ก่อน");
        return;
      }
    }
    let value = null
    let shpmId = initShpmId
    if (dialogData) {
      const postData = {
        ...dialogData,
        getArgs: lastFilter
      }
      dialogData.getArgs = lastFilter;
      value = await ax.post(isInsert ? shipmentApiNm.insertShipment : shipmentApiNm.updateShipment, postData);
      if (isInsert) shpmId = value.data
      if (!value.data) {
        return
      }
    }

    const refreshFromFile = fileUploadData.addedFiles.length > 0 || fileUploadData.deletedFiles.length > 0

    await saveFile({
      ax, alertWarning, fileUploadData, setFileUploadData,
      apiUpdateFileName: shipmentApiNm.updateFileName,
      apiDeleteFile: shipmentApiNm.deleteFile,
      apiAddFile: shipmentApiNm.uploadFile,
      key: "ShpmFileId",
      refKey: "ShpmId",
      refValue: shpmId
    })

    for (const data of memData) {
      if (isInsert) {
        data.RefId = shpmId
      }
      if (data.MemId > 0) {
        await ax.post(coreApiNm.updateMemo, data)
      } else {
        await ax.post(coreApiNm.insertMemo, data)
      }
    }

    if (withPrintJobOrder) {
      await getDataAndPrintJobOrderByShpmId(ax, msData, shpmId, user.FName)
    }

    if (onFinish) {

      if (isInsert) {
        if (isInsertReturnShpmId) {
          onFinish(shpmId)
        } else {
          onFinish(null)
        }
      } else if (dialogData) {
        onFinish(value.data)
      } else if (memData && memData.length > 0) {
        onFinish(null)
      } else if (refreshFromFile) {
        onFinish(null)
      }
    }
    if (isClearData) {
      setDialogOpen(false);
    } else {
      setDialogData(o => ({ ...o, JobNo: "" }))
    }
    console.log("shpmId::", shpmId)
    return shpmId
  }, [ax, lastFilter, onFinish, setDialogOpen, isInsertReturnShpmId, msData, user.FName])

  const handlePrintDialogFinish = useCallback((data) => {
    if (isInsertReturnShpmId) {
      onFinish(mShpmId)
    } else {
      onFinish(data)
    }
  }, [onFinish, isInsertReturnShpmId, mShpmId]
  )

  const handleDialogOK = useCallback(async (withPrintJobOrder) => {
    if (!validate(validation)) return;
    let postDialogData = null;
    if (dialogData.JobOrders && dialogData.JobOrders.length > 0) {
      for (const jo of dialogData.JobOrders) {
        if (!jo.AptTm) {
          alertError("กรุณากรอกเวลานัด")
          return
        }
        if (!jo.NumJobOrd) {
          alertError("กรุณาระบุจำนวนใบงาน")
          return
        }
        jo.ISOCd = getISOCd(jo.ContSize, jo.ContTyp, jo.ContHq)
        console.log("jo.ISOCd::", jo.ISOCd)
      }
    }
    if (JSON.stringify(dialogData) !== JSON.stringify(firstData)) {
      postDialogData = { ...dialogData }
    }
    return await insertUpdateShipment(dialogData.ShpmId, postDialogData, fileUploadData, setFileUploadData, memoData
      , isClearData, withPrintJobOrder)
  }, [validation, dialogData, fileUploadData, memoData, insertUpdateShipment, isClearData])


  //useDialogOpen(dialogOpen, mShpmId, shipmentApiNm.getShipment, "ShpmId", setDialogData, initDialogData, modifyData)
  const handleCopyShipment = useCallback(() => {
    setDialogData(o => ({ ...o, ShpmId: 0 }))
    setmShpmId(0)
  }, [])

  const handlePrintJobOrder = useCallback(() => {
    setDialogPrintJobOrderOpen(true)
  }, [])

  const insertAndPrintJobOrder = useCallback(async () => {
    if (!dialogData.JobOrders || dialogData.JobOrders.length === 0) {
      alertError("กรุณาระบุข้อมูลใบงาน")
      return
    }

    handleDialogOK(true)
    // if (shpmId > 0) {
    //   setDialogData(o => ({ ...o, ShpmId: shpmId }))
    //   setmShpmId(shpmId)
    //   setDialogPrintJobOrderOpen(true)
    // }
  }, [handleDialogOK, dialogData.JobOrders])

  const handlePrintShipmet = useCallback(() => {
    ax.post(shipmentApiNm.getPrintData, { ShpmId: selectedId }).then(value => {
      if (value.data && value.data.length === 1) {
        const shData = value.data[0]
        shData.JobOrders = sortObj(shData.JobOrders, "AptTm", "ContSizeFull")
        printShipment({ shData: shData, msData })
      }
    })
  }, [ax, selectedId, msData])

  const onDialogReadFileFinish = useCallback((data) => {
    console.log("data::", data)
    setDialogData(o => ({
      ...o,
      ...data,
      JobNo: data.Bkg || "",
      ShpmTypId: data.ShpmTyp === "EX" ? 1 : 2,
      JobOrders: Array.isArray(data.VolArr) ? data.VolArr.map(item => ({
        AptTm: null,
        ContSize: item?.Size || "",
        ContTyp: item?.Type?.toUpperCase() === "HQ" ? "GP" : item?.Type?.toUpperCase() || "",
        ContHq: item?.Type?.toUpperCase() === "HQ" ? 1 : 0,
        NumJobOrd: item?.Qty || 1,
      })) : []
    }))

    console.log("")
    setFileUploadData(o => ({
      ...o,
      addedFiles: [
        ...o.addedFiles,
        {
          file: data.file,
          fileNm: data.file.name,
          label: data.file.name,
          isEdit: false,
          imgLink: URL.createObjectURL(data.file)
        }
      ]
    }))
  }, [])

  useEffect(() => {
    // console.log("---------------in useEffect dialogOpen, selectedId, mShpmId::", dialogOpen, selectedId, mShpmId)
    if (dialogOpen) {
      setFileUploadData(JSON.parse(JSON.stringify(initFileUploadData())))
      setmShpmId(selectedId)
      if (selectedId > 0) {
        ax.post(shipmentApiNm.getShipment, { ShpmId: selectedId }).then(value => {
          const serverFiles = value.data[0].Files.map(item => ({
            id: item.ShpmFileId,
            fileNm: item.FileNm,
            label: item.Label,
            oLabel: item.Label,
            ShpmId: selectedId,
            isEdit: false,
            file: null,
            imgLink: `${window.location.origin}/${FOLDER_SHIPMENT_FILE}${item.ShpmId}/${item.FileNm}`,
          }))
          setFileUploadData({ ...initFileUploadData(), serverFiles })

          setDialogData(value.data[0])
          firstData = { ...value.data[0] };
        })
      }
    } else {

      setIsShowShipmentData(true)
      setMemoData([])
      setIsClearData(true)
      setDialogData({ ...initDialogData })
      setDialogPrintJobOrderOpen(false)
      firstData = { ...initDialogData }
      setmShpmId(0)
    }
  }, [dialogOpen, selectedId, ax])

  // console.log("selectedId::", selectedId)
  // console.log("mshpmId::", mShpmId)
  // console.log("oneYearPortData::", oneYearPortData)
  console.log("dialogData::", dialogData)
  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth="lg" >
      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title={mShpmId ? "แก้ไขใบแจ้งลาก" : "เพิ่มใบแจ้งลาก"}
        onDeleteClick={mShpmId > 0 ? () => deleteShipment(mShpmId) : null}
      >
        {mShpmId > 0 ?
          <BoxFR>
            <Button sx={{ ml: 2 }} variant="contained" color="secondary" onClick={handlePrintShipmet}>
              <PrintRounded sx={{ mr: 1 }} /> พิมพ์ใบแจ้งลาก
            </Button>
            <Button variant="contained" color="secondary" onClick={handleCopyShipment}>
              <ContentCopyRounded sx={{ mr: 1 }} /> ทำซำ้ใบแจ้งลาก
            </Button>
            <Button variant="contained" color="secondary" onClick={handlePrintJobOrder}>
              <PrintRounded sx={{ mr: 1 }} /> เพิ่มใบงาน
            </Button>
          </BoxFR>
          :
          <BoxFR ml={2}>
            <Button variant="contained" color="secondary" onClick={() => { setDilaogReadFileOpen(true) }}>
              <DocumentScannerRounded sx={{ mr: 1 }} />
              อ่านจากไฟล์</Button>
          </BoxFR>
        }
      </DialogHeader>
      <DialogContent sx={{ display: "flex", justifyContent: "center", gap: 2, bgcolor: blueGrey[50], pb: 1 }}>
        <Paper sx={{ p: 2, mt: 1 }}>

          <Box display="flex" flexDirection="row" mt={2} gap={4} flex={1} height={680} justifyContent="center">
            <BoxFC mt={-3} sx={{ gap: 1 }}>
              <ShipmentData shipmentData={dialogData}
                setShipmentData={setDialogData}
                msData={msData}
                disabled={false}
                hideModAcc={true}
                validation={validation}
                oneYearVsslData={oneYearVsslData}
                oneYearPortData={oneYearPortData} />
            </BoxFC>
            <BoxFC  sx={{ gap: 1 }}>
              <FileUpload
                width={400}
                options={msData.serverData.PreText.ShipmentFiles || []}
                fileUploadData={fileUploadData}
                setFileUploadData={setFileUploadData}
              />
              {
                dialogData.ShpmId > 0 ?
                  <ModAccName modDte={dialogData.ModDte} modAcc={dialogData.ModAcc} /> :
                  <CheckboxFormControl label="ล้างข้อมูลหลังจากเพิ่มใบแจ้งลาก" checked={isClearData} onChange={(e) => setIsClearData(e.target.checked)} />
              }
            </BoxFC>


            {/* <MemoComponent
            getArgs={dialogData.ShpmId > 0 && getMemoArgs}
            memTyp="SH"
            getApiNm={shipmentApiNm.getShipmentMemo}
            refId={dialogData.ShpmId}
            setMemoData={setMemoData} /> */}
          </Box>
        </Paper>
        <PrintJobOrderDialog
          dialogOpen={dialogPrintJobOrderOpen}
          setDialogOpen={setDialogPrintJobOrderOpen}
          selectedId={mShpmId}
          onFinish={handlePrintDialogFinish} />
        <ReadFileDialog
          dialogOpen={dialogReadFileOpen}
          setDialogOpen={setDilaogReadFileOpen}
          onFinish={onDialogReadFileFinish}
        />
      </DialogContent>
      {isShowShipmentData &&
        <DialogFooter okText={mShpmId > 0 ? "แก้ไขข้อมูล" : "เพิ่มข้อมูล"}
          handleDialogClose={() => setDialogOpen(false)}
          handleDialogOk={() => handleDialogOK(false)} >
          {!mShpmId &&
            <Button variant="contained" color="secondary" onClick={insertAndPrintJobOrder}>
              <PrintRounded sx={{ mr: 1 }} />
              เพิ่มและพิมพ์ใบงาน
            </Button>
          }
        </DialogFooter>
      }
    </Dialog>
  )
}

export default ShipmentDialog