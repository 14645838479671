
export const shipmentApiNm = {
  getShipmentTable: "/shipment/getShipmentTable",
  getShipment: "/shipment/getShipment",
  getShipmentNoJob: "/shipment/getShipmentNoJob",
  getShipmentMemo: "/shipment/getShipmentMemo",
  getOneYearVssl: "/shipment/getOneYearVssl",
  generateJobNo: "/shipment/generateJobNo",
  getPrintData: "/shipment/getPrintData",
  getSendEmailSubcontractData: "/shipment/getSendEmailSubcontractData",
  insertShipment: "/shipment/insertShipment",
  updateShipment: "/shipment/updateShipment",
  deleteShipment: "/shipment/deleteShipment",
  uploadFile: "/shipment/uploadFile",
  uploadReadFile: "/shipment/uploadReadFile",
  updateFileName: "/shipment/updateFileName",
  deleteFile: "/shipment/deleteFile",
}

export const fileLabels =   ["ใบแจ้งลาก", "ใบ Booking", "ใบ B/L"];


export const shipmentColNm = {
  ShpmId: "เลขที่ใบแจ้งลาก",
  ShpmTypId: "ประเภทงาน",
  ShpmTypNm: "ประเภทงาน",
  JobNo: "Job No.",
  Bkg: "Booking",
  Agnt: "Agent",
  Vssl: "Vessel",
  Voy: "Voyage",
  Port: "Port",
  ETD: "ETD",
  ETA: "ETA",
  RecvDocDte: "เวลารับงาน",
  Loc: "สถานที่ส่ง",
  TakePlc: "สถานที่รับ",
  RtnPlc: "สถานที่คืน",
  TakeDteSt: "เริ่มรับได้",
  TakeDteEn: "ปิดรับ",
  RtnDteSt: "เริ่มคืนได้",
  RtnDteEn: "ปิดคืน",
  Rmk: "หมายเหตุ",
  JobId: "ชื่องาน",
  JobSNm: "ชื่องาน",
  CusId: "ชื่อลูกค้า",
  CusSNm: "ชื่อลูกค้า",
  Memo: "Memo",
  Ref1: "อ้างอิง1",
  Ref2: "อ้างอิง2",
  Ref3: "อ้างอิง3",
  Ref4: "อ้างอิง4",
  Ref5: "อ้างอิง5",
  Ref6: "อ้างอิง6"
}



export const initDialogData = {
  ShpmId: 0,
  ShpmTypId: null,
  JobNo: "",
  Bkg: "",
  Agnt: "",
  Vssl: "",
  Voy: "",
  Port: "",
  RecvDocDte: null,
  TakePlc: "",
  RtnPlc: "",
  TakePOIId: null,
  RtnPOIId: null,
  TakeDteSt: null,
  TakeDteEn: null,
  RtnDteSt: null,
  RtnDteEn: null,
  ETD: null, 
  ETA: null,
  Rmk: "",
  JobId: null,
  CusId: null,
  Files: null,
  Ref1: "",
  Ref2: "",
  Ref3: "",
  Ref4: "",
  Ref5: "",
  Ref6: "",
  JORmk: "",
}

export const initFilterData = {
  JobNo: "",
  Bkg: "",
  RecvDocDteSt: null,
  RecvDocDteEn: null,
  CusId: null,
  JobId: null,
}

export const initComboData = {
  cusDialogValue: null,
  cusDialogText: "",
  jobDialogValue: null,
  jobDialogText: "",
  cusFilterValue: null,
  cusFilterText: "",
  jobFilterValue: null,
  jobFilterText: "",
}


export const act = {
  ON_CHANGE_FILTER: "ON_CHANGE_FILTER",
  ON_CHANGE_DIALOG_DATA: "ON_CHANGE_DIALOG_DATA",
  ON_CHANGE_DIALOG_DATA_IMG: "ON_CHANGE_DIALOG_DATA_IMG",
  ON_CHANGE_DIALOG_COMBO: "ON_CHANGE_DIALOG_COMBO",
  CLEAR_FILTER: "FILTER_CLEAR",
  SET_DATA_TABALE: "SET_DATA_TABALE",
  SET_DIALOG_OPEN: "SET_DIALOG_OPEN",
  SET_DIALOG_DATA: "SET_DIALOG_DATA",
  SET_JOB_COMBO_DATA: "SET_JOB_COMBO_DATA",
}

export const SHPM_TYP_EXP = 1
export const SHPM_TYP_IMP = 2
export const SHPM_TYP_INL = 3