import { blue, yellow } from "@mui/material/colors";
import { printHeader } from "branch/functions/pirntHeader";
import dayjs from "dayjs";
import jsPDF from "jspdf";
import { numberFormat } from "utils";


export const printPcPeriodDriver = ({ dataTable, msData }) => {

  const companyData = msData.serverData.CompanyData
  const startY = 0.3
  const startX = 0.5
  const pageSize = [8.3, 11.7];
  const lineHeight = 0.21
  const contentSize = 14;
  let yPos = startY
  let xPos = startX
  const doc = new jsPDF({
    orientation: "landscape",
    unit: "in",
    format: pageSize
  });
  doc.setLineWidth(0.01)
  doc.setFont("THSarabun")

  let pageNo = 1

  doc.setFont("THSarabun")
  if (pageNo !== 1) {
    doc.addPage(pageSize, "portrait")
  }
  xPos = startX
  yPos = startY
  yPos = printHeader({ jsPDFDoc: doc, companyData, startX: xPos, startY: yPos })

  //---------------------------------draw top right -------------------------------------
  yPos = startY + 0.2
  doc.setFont("THSarabun", "bold")
  doc.setFontSize(20)
  doc.text("รายงานการใช้เงิน", 9.8, yPos, { align: "center" })

  doc.setFont("THSarabun", "bold")
  doc.setFontSize(contentSize)
  const lineHeightTopRight = lineHeight + lineHeight / 5
  const yPosTopRight = yPos + lineHeightTopRight
  const xPosTopRight = 8.9
  xPos = xPosTopRight
  yPos = yPosTopRight

  doc.text("บัญชี :", xPos, yPos)
  yPos += lineHeightTopRight
  doc.text("วันที่ : ", xPos, yPos)
  yPos += lineHeightTopRight
  doc.setFont("THSarabun", "normal")
  yPos = yPosTopRight
  xPos += 0.4
  // doc.setFontSize(contentSize + 3)
  // doc.setFont("THSarabun", "bold")
  doc.text("บัตรเงินสด", xPos, yPos)
  // doc.setFontSize(contentSize)
  // doc.setFont("THSarabun", "normal")
  yPos += lineHeightTopRight
  doc.text(dayjs().format("DD/MM/YYYY 15:00"), xPos, yPos)
  yPos += lineHeightTopRight

  yPos += lineHeight 
  
  
  const startTableX = startX - 0.15

  //-------------------------------------- Box Header --------------------------------------------------------
  const colNoW = 0.35
  const colDrvCdW = 0.6
  const colDrvNmW = 1.5
  const colDrvAccNoW = 1.5
  const colAdvW = 1
  const colGateW = 0.8
  const colOtW = 0.8
  const colTollW = 0.8
  const colLiftEW = 0.8
  const colFeeW = 0.8
  const colEVW = 0.8
  const colTotalExpW = 0.8
  // const colTotalOut = 0.8
  // const colGrndTotal = 0.8
  const dataBoxW = colNoW + colDrvCdW + colDrvNmW + colDrvAccNoW + colAdvW + colGateW + colOtW + colTollW + colLiftEW + colFeeW + colEVW + colTotalExpW
  
  xPos = startTableX
  doc.setFillColor(blue[100])

  doc.rect(xPos, yPos, dataBoxW, lineHeight + 0.1, "F")
  doc.line(xPos, yPos, xPos + dataBoxW, yPos)
  //------------------------------------- Text Header ----------------------------------------------------------
  yPos += lineHeight
  xPos = startTableX
  const drawHeader = (label, width) => {
    doc.text(label, xPos + width / 2, yPos, { align: "center" })
    xPos += width
  }
  const drawText = (text, width) => {
    doc.text(text, xPos + 0.1, yPos, )
    xPos += width
  }
  // doc.text("Job No.", xPos + jobNoColW / 2, yPos, { align: "center" })
  // xPos += jobNoColW
  drawHeader("No.", colNoW)
  drawHeader("รหัส", colDrvCdW)
  drawHeader("พนักงานขับรถ", colDrvNmW)
  drawHeader("เลขที่ บช.", colDrvAccNoW)
  drawHeader("เงินเบิก", colAdvW)
  drawHeader("ผ่านท่า", colGateW)
  drawHeader("ค่าล่วงเวลา", colOtW)
  drawHeader("ทางด่วน", colTollW)
  drawHeader("ค่ายกตู้เปล่า", colLiftEW)
  drawHeader("ค่าธรรมเนียม", colFeeW)
  drawHeader("ค่าชาร์ตไฟ", colEVW)
  // drawHeader("ค่าอื่นๆ", colOthW)
  drawHeader("รวมค่าใช้จ่าย", colTotalExpW)
  // drawHeader("เบิกล่วงหน้า", colTotalOut)
  // drawHeader("เงินขาด-เกิน", colGrndTotal)
  yPos += 0.1
  doc.line(startTableX, yPos, startTableX + dataBoxW, yPos)

  //------------------------------------- Data ----------------------------------------------------------
  const drawNumberTable = (number, width) => {
    doc.text(`${numberFormat(number)}`, xPos + width, yPos, { align: "right" })
    xPos += width
  }
  doc.setFont("THSarabun", "normal")
  yPos += lineHeight
  xPos = startTableX
  for (const data of dataTable) {
    xPos = startTableX
    console.log("data::", data)
    if (data.id === 0) {
      doc.setFont("THSarabun", "bold")
      doc.setFillColor(yellow[100])
      doc.rect(startTableX, yPos - 0.1, dataBoxW, lineHeight + 0.1, "F")
      doc.line(startTableX, yPos - 0.1, startTableX + dataBoxW, yPos - 0.1)
      yPos += 0.1
    }
    drawHeader(`${data.No || ""}.`, colNoW)
    drawHeader(`${data.DrvCd || "-"}`, colDrvCdW)
    drawText(`${data.DrvNm || "-"}`, colDrvNmW)
    drawText(`${data.BkAccNo || "-"}`, colDrvAccNoW)
    drawNumberTable(+data.AdvAmnt, colGateW)
    drawNumberTable(+data.Gate, colGateW)
    drawNumberTable(+data.Ot, colOtW)
    drawNumberTable(+data.Toll, colTollW)
    drawNumberTable(+data.LiftE, colLiftEW)
    drawNumberTable(+data.Fee, colGateW)
    drawNumberTable(+data.EV, colTollW)
    // drawNumberTable(+data.OthAmnt, colOthW)
    drawNumberTable(+data.Total, colTotalExpW)
    // drawNumberTable(+data.TotalOut, colTotalOut)
    // drawNumberTable(+(data.ExpTotal || 0) - data.TotalOut, colGrndTotal)
    yPos += lineHeight
  }

  doc.line(startTableX, yPos - 0.1, startTableX + dataBoxW, yPos - 0.1)

  yPos += (lineHeight * 3) + 0.1

  doc.setFont("THSarabun", "normal")
  const signWidth = 1.5
  const startsignX = startTableX + 1
  const col1 = startsignX
  const col2 = startsignX + dataBoxW * 1/3
  const col3 = startsignX + dataBoxW * 2/3
  // const col4 = startsignX + dataBoxW * 3/4

  doc.line(col1, yPos, col1 + signWidth, yPos)
  doc.line(col2, yPos, col2 + signWidth, yPos)
  doc.line(col3, yPos, col3 + signWidth, yPos)
  // doc.line(col4, yPos, col4 + signWidth, yPos)
  yPos += lineHeight
  doc.text("ผู้จัดทำ", col1 + signWidth / 2, yPos, { align: "center" })
  doc.text("ผู้ตรวจสอบ", col2 + signWidth / 2, yPos, { align: "center" })
  doc.text("ผู้ตรวจสอบ", col3 + signWidth / 2, yPos, { align: "center" })
  // doc.text("ผู้ตรวจสอบ", col4 + signWidth / 2, yPos, { align: "center" })

  doc.output('dataurlnewwindow');
}
