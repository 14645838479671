import { Dialog, DialogContent, MenuItem, TextField, Typography } from "@mui/material"
import { BoxFC, BoxFR } from "components/BoxCustom"
import PaperComponent from "components/PaperComponent"
import { DialogFooter, DialogHeader } from "components/dialog/DialogHeader"
import { GlobalStateContext } from "contexts/GlobalStateContext"
import React, { useCallback, useContext, useEffect, useState } from "react"
import { repairOrderApiNm, repairOrderColNm } from "./constant"
import DateTimePickerCustomTime from "components/DateTimePickerCustomTime"
import { initDialogData } from "./initData"
import { DesktopDatePicker } from "@mui/x-date-pickers-pro"
import ComboBox from "components/ComboBox"
import ThumbnaiImageList from "./ThumbnaiImageList"
import FullScreenImage from "pages/FullScreenImage/FullScreenImage"
import { PhotoRounded } from "@mui/icons-material"
import { blueGrey, grey } from "@mui/material/colors"
import { resizeImage } from "utils/resizeImage"
import { alertConfirmDelete } from "components/Alert"

let oDialogData = { ...initDialogData }
const addedFiles = []
const deletedFiles = []
const RepairOrderDialog = ({ selectedId, dialogOpen, setDialogOpen, onFinish, lastFilter }) => {

  const { ax, msData } = useContext(GlobalStateContext)
  const [dialogData, setDialogData] = useState({ ...initDialogData })
  const [files1, setfiles1] = useState([])
  const [files2, setfiles2] = useState([])
  const [files3, setfiles3] = useState([])
  const [curImage, setCurImage] = useState("")

  const textFieldProp = useCallback((name) => ({
    size: "small",
    label: repairOrderColNm[name],
    value: dialogData[name],
    onChange: (e) => setDialogData(o => ({ ...o, [name]: e.target.value }))
  }), [dialogData])

  const dateTimePickerProp = useCallback((name) => ({
    label: repairOrderColNm[name],
    inputFormat: "DD/MM/YYYY HH:mm",
    mask: "__/__/____ __:__",
    value: dialogData[name],
    onChange: (newValue) => { setDialogData(o => ({ ...o, [name]: newValue })) },
    renderInput: (params) => <TextField size="small" {...params} />,
  }), [dialogData])

  const dateickerProp = useCallback((name) => ({
    label: repairOrderColNm[name],
    inputFormat: "DD/MM/YYYY",
    mask: "__/__/____",
    value: dialogData[name],
    onChange: (newValue) => { setDialogData(o => ({ ...o, [name]: newValue })) },
    renderInput: (params) => <TextField size="small" {...params} />,
  }), [dialogData])


  const handleDeleteRepairOrder = useCallback(() => {
    alertConfirmDelete(() => {
      ax.post(repairOrderApiNm.deleteRepairOrder, { ROId: selectedId, getArgs: lastFilter }).then(value => {
        if (value.data) {
          onFinish(value.data)
          setDialogOpen(false)
        }
      })
    })
  }, [ax, lastFilter, selectedId, onFinish, setDialogOpen])

  const handleSave = useCallback(async () => {
    let isAllSame = true
    for (const key in dialogData) {
      if (dialogData[key] !== oDialogData[key]) {
        isAllSame = false
        break
      }
    }
    let roId = selectedId
    let res = null
    let hasChange = false
    if (!isAllSame) {
      hasChange = true
      let apiNm = selectedId ? repairOrderApiNm.updateRepairOrder : repairOrderApiNm.insertRepairOrder
      const poastData = {
        ...dialogData,
        getArgs: selectedId? lastFilter: null
      }
      res = await ax.post(apiNm, poastData)
    }
    if (res.data) {
      if (!selectedId) {
        roId = res.data
      }
    }
    if (roId && addedFiles.length > 0) {
      hasChange = true
      for (const file of addedFiles) {
        // console.log("file::", file) 
        const resizedFile = await resizeImage(file)
        const formData = new FormData()
        formData.append("file", resizedFile)
        formData.append("Label", file.label)
        formData.append("ROStsId", file.typId)
        formData.append("ROId", roId)

        await ax.post(repairOrderApiNm.uploadFile, formData)
      }
    }
    if (roId && deletedFiles.length > 0) {
      hasChange = true
      await ax.post(repairOrderApiNm.deleteFile, { ROFileIds: deletedFiles.map(file => file.ROFileId) })
    }
    if(hasChange){
      if(selectedId){
        onFinish(res.data)
      } else {
        onFinish(null)
      }
    }
    setDialogOpen(false)
  }, [ax, dialogData, onFinish, selectedId, setDialogOpen, lastFilter])

  const handleAddImage = useCallback((setFiles) => (files) => {
    const minId = addedFiles.reduce((min, file) => (file.id < min ? file.id : min), 0);
    for (const file of files) {
      file.ROFileId = minId - 1
    }
    addedFiles.push(...files)
    setFiles(o => [...o, ...files])
  }, [])

  const handleRemoveImage = useCallback((setFiles) => (index) => {
    setFiles(o => {
      const newFiles = [...o]
      const removedFile = newFiles.splice(index, 1)
      if (removedFile[0].ROFileId > 0) {
        deletedFiles.push(removedFile[0])
      } else {
        addedFiles.splice(addedFiles.findIndex(file => file.ROFileId === removedFile[0].ROFileId), 1)
      }
      return newFiles
    })
  }, [])

  const handleImageClick = useCallback((img) => {
    setCurImage(img.imgLink)
  }, [])

  useEffect(() => {
    if (dialogOpen) {
      if (selectedId) {
        ax.post(repairOrderApiNm.getRepairOrderDetail, { ROId: selectedId }).then(value => {
          if (value.data) {
            if (value.data.Files) {
              const f1 = value.data.Files.filter(file => file.ROStsId === 1)
              const f2 = value.data.Files.filter(file => file.ROStsId === 2)
              const f3 = value.data.Files.filter(file => file.ROStsId === 3)
              setfiles1(f1.map(file => ({ ...file })))
              setfiles2(f2.map(file => ({ ...file })))
              setfiles3(f3.map(file => ({ ...file })))
            }
            delete value.data.Files
            oDialogData = { ...value.data }
            setDialogData(value.data)

          }
        })
      }
    } else {
      setDialogData({ ...initDialogData })
      setfiles1([])
      setfiles2([])
      setfiles3([])
      setCurImage("")
      addedFiles.length = 0
      deletedFiles.length = 0
    }
  }, [dialogOpen, ax, selectedId])

  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth
      PaperProps={{
        style: {
          minWidth: 1650,
          width: 1650,
        }
      }}
    >
      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title="รายละเอียดการซ่อม"
        onDeleteClick={selectedId ? handleDeleteRepairOrder : null}
      />
      <DialogContent>
        <BoxFR pt={2} sx={{ alignItems: "stretch" }} height={630}>
          <BoxFC sx={{ gap: 1 }} width={400} >
            <TextField {...textFieldProp("ROStsId")} select >
              {msData.repairOrderStatuses.map((item) => (
                <MenuItem key={item.ROStsId} value={item.ROStsId}>
                  {item.ROStsNm}
                </MenuItem>
              ))}
            </TextField>
            <DateTimePickerCustomTime {...dateTimePickerProp("ROTm")} />
            <ComboBox sx={{ width: "100%" }} options={msData.driverOnlyCombo} label={"พนักงานขับรถ"}
              selectedId={dialogData.DrvId}
              setSelectedId={(id) => setDialogData(o => ({ ...o, DrvId: id }))}
            />
            <ComboBox sx={{ width: "100%" }} options={msData.truckCombo} label={"เลขรถ"}
              selectedId={dialogData.TukId}
              setSelectedId={(id) => setDialogData(o => ({ ...o, TukId: id }))}
            />
            <TextField {...textFieldProp("OrdHdr")} />
            <TextField {...textFieldProp("OrdDscp")} multiline rows={4} />
            <TextField {...textFieldProp("ReprDscp")} multiline rows={4} />
            <DesktopDatePicker {...dateickerProp("StDte")} />
            <DesktopDatePicker {...dateickerProp("EstFinDte")} />
            <DesktopDatePicker {...dateickerProp("FinDte")} />
          </BoxFC>
          <BoxFC width={420}>

            <ThumbnaiImageList
              label="รูปแจ้งซ่อม" sx={{ height: 194 }} typId={1}
              imageItems={files1}
              onAddImage={handleAddImage(setfiles1)}
              onRemoveImage={handleRemoveImage(setfiles1)}
              onImageClick={handleImageClick} />
            <ThumbnaiImageList
              label="รูปการซ่อม" sx={{ height: 194 }} typId={2}
              imageItems={files2}
              onAddImage={handleAddImage(setfiles2)}
              onRemoveImage={handleRemoveImage(setfiles2)}
              onImageClick={handleImageClick} />
            <ThumbnaiImageList
              label="รูปหลังซ่อม" sx={{ height: 194 }} typId={3}
              imageItems={files3}
              onAddImage={handleAddImage(setfiles3)}
              onRemoveImage={handleRemoveImage(setfiles3)}
              onImageClick={handleImageClick} />

          </BoxFC>

          <BoxFC flex={1} bgcolor={blueGrey[50]} borderRadius={1} height="100%"
            sx={{ justifyContent: "center", alignItems: "center" }}>
            {curImage ?
              <FullScreenImage linkUrl={curImage} />
              :
              <BoxFC sx={{ gap: 0 }} justifyContent="center" alignItems="center" width="100%" height="100%">
                <PhotoRounded sx={{ fontSize: 50, color: grey[500] }} />
                <Typography variant="h6" sx={{ color: grey[500] }}>กรุณาเลือกรูป</Typography>
              </BoxFC>
            }

          </BoxFC>
        </BoxFR>
      </DialogContent>
      <DialogFooter okText={"บันที่กข้อมูล"}
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={handleSave} />
    </Dialog>
  )
}

export default React.memo(RepairOrderDialog)