
import { Typography, Box, IconButton } from "@mui/material";
import { BoxFR } from "components/BoxCustom";
import ComboBox from "components/ComboBox";
import dayjs from "dayjs";
import React, { useMemo } from "react";
import { useCallback } from "react";
import { useState } from "react";
import EditContStsTmDialog from "./EditContStsTmDialog";
import FactCheckRoundedIcon from '@mui/icons-material/FactCheckRounded';
import { EditRounded, PersonPin, Schedule } from "@mui/icons-material";
import { grey } from "@mui/material/colors";

const ContainerStatusItem = ({ msData, contStsData, setContStsData, options, setJobOrderData, onChange
  , hideDriverActTm, hideIsCmpt, processDrvId }) => {
  
  const [dialogOpen, setDialogOpen] = useState(false)

  const disabledProcessDrvId = useMemo(()=>contStsData.DrvId || !processDrvId, [contStsData.DrvId, processDrvId])
  const handleChangeStsTm = useCallback((newValue) => {
    contStsData.StsTm = newValue
    setContStsData(oldData => [...oldData])
  }, [contStsData, setContStsData])

  const handleOpenDialog = useCallback(() => {
    setDialogOpen(true)
  }, [])

  const handleIsCmptClick = useCallback((e) => {
    contStsData.IsCmpt = contStsData.IsCmpt === 1 ? 0 : 1
    setContStsData(oldData => [...oldData])
  }, [contStsData, setContStsData])

  const handleDrvIdChange = useCallback((id) => {
    const tukId = msData.drivers.find(drv => drv.DrvId === id)?.TukId || null
    contStsData.DrvId = id;
    contStsData.StsTm = id ? dayjs() : null
    contStsData.TukId = tukId
    if (setJobOrderData) {
      setJobOrderData(o => {
        //only if stsId != 1 stsTm will be equal to AptTm if now is after apttm
        //20241230 change back to normal that change StsTm to now
        
        // if (contStsData.ContStsId !== 1) {
        //   contStsData.StsTm = dayjs().isAfter(dayjs(o.AptTm)) ? dayjs() : dayjs(o.AptTm)
        // }
        setContStsData(oldData => [...oldData])
        return {
          ...o,
          DrvId: id,
          DrvActTm: id === null ? null : dayjs(),
          TukId: tukId,
          processDrvId: id
        }
      })
    } else {
      setContStsData(oldData => [...oldData])
    }
    if (onChange) {
      onChange(id)
    }
  }, [contStsData, setContStsData, setJobOrderData, onChange, msData.drivers])

  const handleProcessDrvIdClick = useCallback(() => {
    handleDrvIdChange(processDrvId)
  }, [handleDrvIdChange, processDrvId])
  // console.log("in render ContainerStatusItem processDrvId::", processDrvId)
  return (
    <Box gap={0.5} sx={{
      padding: 0.5,
      display: "flex",
      flexDirection: "column",
      bgcolor: contStsData.DrvId && `${contStsData.BgClr}.main`,
      border: "2px solid ",
      borderColor: `${contStsData.BgClr}.main`,
      borderRadius: 2,
      height: 92,
      flex: 1
    }}>
      <BoxFR>
        <IconButton disabled={disabledProcessDrvId?true:false} sx={{p: 0, bgcolor: "white", "&:hover": {bgcolor: grey[300]}}}
          onClick={handleProcessDrvIdClick}>
          <PersonPin color={disabledProcessDrvId?"disabled":"primary"}/>
        </IconButton>
        <Typography color={contStsData.DrvId && "#FFFFFF"}>{contStsData.Name} </Typography>
        <Box flex={1} />
        {hideDriverActTm ? null :
          <BoxFR sx={{ gap: 0.5 }}>
            <Schedule sx={{ px: 0, mx: 0, color: contStsData.DrvId ? "white" : "black" }} />
            <Typography color={contStsData.DrvId && "#FFFFFF"}>{contStsData.StsTm ? dayjs(contStsData.StsTm).format("DD/MM HH:mm") : "วัน-เวลา"}</Typography>
            <IconButton sx={{ p: 0 }} onClick={handleOpenDialog}>
              <EditRounded sx={{ px: 0, mx: 0, color: contStsData.DrvId ? "white" : "black" }} />
            </IconButton>
          </BoxFR>
        }
      </BoxFR>
      <BoxFR display="flex" gap={1}>
        <ComboBox sx={{ flexGrow: 1 }} options={options || msData.driverCombo} required={false}
          selectedId={contStsData.DrvId}
          setSelectedId={handleDrvIdChange}
        />
        <ComboBox sx={{ flexGrow: 1 }} options={msData.truckCombo} required={false}
          selectedId={contStsData.TukId}
          setSelectedId={(id) => {
            contStsData.TukId = id;
            setContStsData(oldData => [...oldData])
            if (setJobOrderData) {
              setJobOrderData(o => ({
                ...o,
                TukId: id
              }))
            }
          }}
        />
        {hideIsCmpt ? null :
          <IconButton sx={{ p: 0.5, backgroundColor: "white" }} onClick={handleIsCmptClick} disabled={contStsData.DrvId ? false : true}>
            <FactCheckRoundedIcon color={contStsData.IsCmpt === 1 ? "success" : "inherit"} />
          </IconButton>
        }

      </BoxFR>
      <EditContStsTmDialog dialogOpen={dialogOpen} setDialogOpen={setDialogOpen} initValue={dayjs(contStsData.StsTm)} setContStsTm={handleChangeStsTm} />
    </Box>
  )
}

export default ContainerStatusItem